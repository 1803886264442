<script setup lang="ts">
    import defaultImage from '@/assets/images/empty-image.svg';
    import { formatCurrency, totalHours } from '@/helpers/formatting';
    import { type PackageTyping } from '@/typings/widget';
    import AdvancedImage from '@shared/components/ui/advanced-image.vue';

    const { t, locale } = useI18n();

    const props = defineProps<{
        package: PackageTyping
    }>();
</script>

<template>
    <div class="package_item">
        <div class="package_item__image">
            <AdvancedImage
                v-if="package?.cover"
                :media="package.cover"
                :preview="defaultImage"
                :asset-width="500"
                no-crop
                aspect-ratio="2:1"
            />
            <img
                v-else
                :src="defaultImage"
            >
            <div class="item-description-hours">
                {{ totalHours(props.package?.programme) }}
            </div>
        </div>
        <div class="package_item__text">
            <div class="content">
                <div class="title">
                    {{ props.package.name }}<br>
                </div>
                <div class="description">
                    {{ props.package.eventType.name }}
                </div>
            </div>
            <div class="price">
                <span>
                    {{ t('widget.global.from') }} {{ formatCurrency(props.package?.pricePerPerson, props.package?.currency, locale) }}
                </span>
                {{ t('widget.global.guest') }}
            </div>
        </div>
    </div>
</template>

<style scoped lang="scss">
.package_item {
    position: relative;
    display: flex;
    overflow: hidden;
    flex-direction: column;
    padding: 0;
    border: 1px solid var(--border-tertiary);
    border-radius: var(--border-radius-sm);
    background: var(--background-secondary);
    block-size: 100%;
    cursor: pointer;

    .package_item__image {
        position: relative;
    }

    .package_item__text {
        display: flex;
        flex: 1;
        flex-direction: column;
        justify-content: space-between;
        padding: var(--spacing-2x);
        border-radius: 0 0 var(--border-radius-sm) var(--border-radius-sm);

        .title {
            font-size: 16px;
            font-weight: 500;
        }

        .description {
            color: var(--text-secondary);
            font-size: 14px;
            margin-block-start: 8px;
        }
    }

    .price {
        font-size: 14px;
        font-weight: 300;
        margin-block-start: 32px;

        span {
            font-size: 16px;
            font-weight: 500;
        }
    }

    .item-description-hours {
        position: absolute;
        display: inline-block;
        border-radius: 5px;
        background-color: var(--background-primary);
        font-size: 12px;
        inset-block-end: 16px;
        inset-inline-end: 16px;
        padding-block: 5px;
        padding-inline: 8px;
    }

    &:hover {
        background: var(--background-secondary-highlight);
    }
}
</style>
