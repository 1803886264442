{
  "localize": {
    "hours": {
      "short": "{hours}u"
    },
    "hoursAndMinutes": {
      "short": "{hours}u{minutes}m"
    }
  },
  "widget": {
    "titles": {
      "sent-us-request": "Arrangement aanvragen",
      "amazing": "Fantastisch!",
      "ourPackages": "Onze arrangementen",
      "ask-name": "Wat is je voornaam?",
      "event-type-title": "Aangenaam {name}!",
      "event-type-subtitle": "Welk event wil je organiseren?",
      "survey-organizer-title": "Wat voor organisator ben jij?",
      "survey-organizer-subtitle": "Selecteer wat het beste bij je past.",
      "survey-theme-title": "En aan welke sfeer denk je voor je event?",
      "survey-theme-subtitle": "Selecteer één van deze thema's",
      "survey-appointment-title": "Is je datum flexibel?",
      "survey-appointment-subtitle": "Selecteer jouw voorkeur"
    },
    "global": {
      "from": "Vanaf",
      "guest": "pp",
      "made-constell": "Door Constell, met liefde",
      "loading": "Laden...",
      "error-wrong": "Er ging iets mis",
      "guests": "gasten"
    },
    "package-list": {
      "no-results": "Geen arrangementen gevonden"
    },
    "package": {
      "included": "Inbegrepen",
      "generic-timeslot": "Algemeen",
      "all-day": "Hele dag"
    },
    "buttons": {
      "plan-event": "Plan je event",
      "close": "Sluiten",
      "book-it": "Aanvragen",
      "send-request": "Versturen",
      "back-main": "Terug naar overzicht",
      "continue": "Verder",
      "next": "Volgende",
      "send": "Vestuur"
    },
    "messages": {
      "sent-us-request": "Wij sturen je zo snel mogelijk een passende vrijblijvende offerte toe.",
      "request-success": "Je offerte aanvraag is ontvangen.",
      "errors": {
        "list-failed": "Er ging iets mis bij het ophalen van de arrangementen.",
        "request-failed": "Er ging iets mis bij het versturen van je verzoek."
      }
    },
    "navigation": {
      "back": "Terug"
    },
    "form": {
      "all-events": "Alle events",
      "event-date": "Gewenste datum",
      "guest-amount": "Aantal gasten",
      "your-name": "Naam",
      "your-surname": "Achternaam",
      "email": "Jouw e-mail",
      "company-name": "Bedrijfsnaam",
      "phone-number": "Telefoonnummer",
      "name": "Naam",
      "description": "Moet ik nog iets weten?",
      "validations": {
        "required": "{field} is verplicht"
      }
    },
    "welcome": {
      "hello": "Hallo!",
      "by": "Welkom bij",
      "desc": "Maak, zoek & boek hier jouw",
      "start": "Start offerte aanvraag",
      "see": "Zie arrangementen"
    },
    "selection": {
      "quick": "Snel en makkelijk",
      "quick-desc": "Bekijk onze arrangementen en kant-en-klare events",
      "quick-action": "Zie arrangementen",
      "journey": "Vervolg je reis",
      "journey-desc": "Jouw offerte & event op maat met mijn advies en hulp.",
      "journey-action": "Samen verder"
    },
    "survey-organizer": {
      "pete-precise": "Pietje-precies",
      "perfectionist": "Praktisch",
      "delightfully-flexible": "Lekker flexibel",
      "practical": "Chaoot / YOLO"
    },
    "survey-theme": {
      "intieme-gezellig": "Intiem & gezellig",
      "chic-formeel": "Chic & Formeel",
      "feest-dansen": "Feest & Dansen",
      "informeel-gezellig": "Informeel & gezellig"
    },
    "survey-appointment": {
      "date-only": "Alleen {weekday} {day} {month} {year}",
      "date-some": "Een {weekday} in {month} {year}",
      "around-period": "Rond die periode",
      "negotiable": "Alles is bespreekbaar"
    },
    "date": {
      "when": "Wanneer is de grote dag?",
      "available": "(afhankelijk van beschikbaarheid)"
    },
    "amount": {
      "great": "Leuk {name}. Ik weet al wat meer!",
      "now": "Laten we het nu over je gasten hebben...",
      "how-many": "Hoeveel gasten verwacht je op jouw {event}?"
    },
    "descriptions": {
      "title": "Hoe zou je ze omschrijven?",
      "subtitle": "Selecteer verschillende opties",
      "eaters": "Grote eters",
      "small": "Kleine magen",
      "improv": "Koning van impro",
      "party": "Feestbeesten",
      "diet": "Speciaal dieet",
      "vegetarian": "Vegetarisch",
      "vegan": "Veganistisch",
      "sporty": "Sportief",
      "old": "Senior",
      "adult": "Volwassen",
      "kid": "Kinderen",
      "pregnant": "Zwanger",
      "handicap": "Minder valide",
      "dutch": "Nederlands",
      "english": "Internationale gasten",
      "alcohol": "Alcoholvrij"
    },
    "budget": {
      "title": "Wat is je budget?"
    },
    "host": {
      "title-1": "Ik ben {name},",
      "text-1": "jouw eventmanager, laten we jouw perfecte event maken.",
      "title-2": "Leuk je te leren kennen!",
      "title-2-5": "Wat leuk! Gefeliciteerd!",
      "title-3": "Hoe wil je verder?",
      "title-4": "Fantastisch!",
      "title-5": "Dat is nog {days} dag te gaan",
      "title-6": "Dat is nog {days} dagen te gaan",
      "title-6-5": "Ik ga kijken wat ik kan doen",
      "title-7": "Om je te leren kennen!",
      "title-8": "Goed om te weten!",
      "title-9": "Wat een goede keuze!",
      "title-10": "Geef hier vast een afspraak-suggestie aan"
    },
    "appointment": {
      "title": "Wil je alvast een afspraak maken?",
      "subtitle": "om de offerte te bespreken",
      "yes": "Ja",
      "no": "Nee"
    },
    "loading": {
      "title": "Geweldig!!",
      "subtitle": "Alles is ingevuld en de resultaten worden gemaakt."
    },
    "summary": {
      "title": "{name}, je hebt geselecteerd",
      "desc": "We hebben jouw perfecte {event} samengesteld…"
    },
    "results": {
      "title": "Jouw resultaat",
      "subtitle": "Gefeliciteerd, een uniek event voor jou!",
      "packages": "{number} arrangementen!",
      "disc": "Misschien vind je deze arrangementen wel leuk",
      "options": {
        "intimate": "Intiem",
        "big": "Groot",
        "festive": "Feestelijk",
        "elegant": "Elegant",
        "simple": "Simpel",
        "unique": "Uniek",
        "international": "Internationaal",
        "dutch": "Nederlands",
        "talk": "Praten",
        "dance": "Dansen"
      },
      "form": {
        "title": "Maak je droom nu waar",
        "desc": "Stuur je antwoorden naar ons toe en ontvang een unieke offerte.",
        "name": "Naam",
        "company": "Bedrijfsnaam",
        "email": "Je e-mail",
        "description": "Moet ik nog iets weten?"
      }
    }
  },
  "countries": {
    "AD": "Andorra",
    "AE": "Verenigde Arabische Emiraten",
    "AF": "Afghanistan",
    "AG": "Antigua en Barbuda",
    "AI": "Anguilla",
    "AL": "Albanië",
    "AM": "Armenië",
    "AO": "Angola",
    "AQ": "Antarctica",
    "AR": "Argentinië",
    "AS": "Amerikaans-Samoa",
    "AT": "Oostenrijk",
    "AU": "Australië",
    "AW": "Aruba",
    "AX": "Åland",
    "AZ": "Azerbeidzjan",
    "BA": "Bosnië en Herzegovina",
    "BB": "Barbados",
    "BD": "Bangladesh",
    "BE": "België",
    "BF": "Burkina Faso",
    "BG": "Bulgarije",
    "BH": "Bahrein",
    "BI": "Burundi",
    "BJ": "Benin",
    "BL": "Saint-Barthélemy",
    "BM": "Bermuda",
    "BN": "Brunei",
    "BO": "Bolivia",
    "BQ": "Bonaire, Sint Eustatius en Saba",
    "BR": "Brazilië",
    "BS": "Bahama's",
    "BT": "Bhutan",
    "BV": "Bouvet",
    "BW": "Botswana",
    "BY": "Wit-Rusland",
    "BZ": "Belize",
    "CA": "Canada",
    "CC": "Cocoseilanden",
    "CD": "Democratische republiek Congo",
    "CF": "Centraal-Afrikaanse Republiek",
    "CG": "Congo",
    "CH": "Zwitserland",
    "CI": "Ivoorkust",
    "CK": "Cookeilanden",
    "CL": "Chili",
    "CM": "Kameroen",
    "CN": "China",
    "CO": "Colombia",
    "CR": "Costa Rica",
    "CU": "Cuba",
    "CV": "Kaapverdië",
    "CW": "Curaçao",
    "CX": "Christmaseiland",
    "CY": "Cyprus",
    "CZ": "Tsjechië",
    "DE": "Duitsland",
    "DJ": "Djibouti",
    "DK": "Denemarken",
    "DM": "Dominica",
    "DO": "Dominicaanse Republiek",
    "DZ": "Algerije",
    "EC": "Ecuador",
    "EE": "Estland",
    "EG": "Egypte",
    "EH": "Westelijke Sahara",
    "ER": "Eritrea",
    "ES": "Spanje",
    "ET": "Ethiopië",
    "FI": "Finland",
    "FJ": "Fiji",
    "FK": "Falklandeilanden",
    "FM": "Micronesië",
    "FO": "Faeröer",
    "FR": "Frankrijk",
    "GA": "Gabon",
    "GB": "Verenigd Koninkrijk",
    "GD": "Grenada",
    "GE": "Georgië",
    "GF": "Frans-Guyana",
    "GG": "Guernsey",
    "GH": "Ghana",
    "GI": "Gibraltar",
    "GL": "Groenland",
    "GM": "Gambia",
    "GN": "Guinee",
    "GP": "Guadeloupe",
    "GQ": "Equatoriaal-Guinea",
    "GR": "Griekenland",
    "GS": "Zuid-Georgia en de Zuidelijke Sandwicheilanden",
    "GT": "Guatemala",
    "GU": "Guam",
    "GW": "Guinee-Bissau",
    "GY": "Guyana",
    "HK": "Hongkong",
    "HM": "Heard en McDonaldeilanden",
    "HN": "Honduras",
    "HR": "Kroatië",
    "HT": "Haïti",
    "HU": "Hongarije",
    "ID": "Indonesië",
    "IE": "Ierland",
    "IL": "Israël",
    "IM": "Man",
    "IN": "India",
    "IO": "Brits Indische Oceaanterritorium",
    "IQ": "Irak",
    "IR": "Iran",
    "IS": "IJsland",
    "IT": "Italië",
    "JE": "Jersey",
    "JM": "Jamaica",
    "JO": "Jordanië",
    "JP": "Japan",
    "KE": "Kenia",
    "KG": "Kirgizië",
    "KH": "Cambodja",
    "KI": "Kiribati",
    "KM": "Comoren",
    "KN": "Saint Kitts en Nevis",
    "KP": "Noord-Korea",
    "KR": "Zuid-Korea",
    "KW": "Koeweit",
    "KY": "Kaaimaneilanden",
    "KZ": "Kazachstan",
    "LA": "Laos",
    "LB": "Libanon",
    "LC": "Saint Lucia",
    "LI": "Liechtenstein",
    "LK": "Sri Lanka",
    "LR": "Liberia",
    "LS": "Lesotho",
    "LT": "Litouwen",
    "LU": "Luxemburg",
    "LV": "Letland",
    "LY": "Libië",
    "MA": "Marokko",
    "MC": "Monaco",
    "MD": "Moldavië",
    "ME": "Montenegro",
    "MF": "Sint Maarten (Frans deel)",
    "MG": "Madagaskar",
    "MH": "Marshalleilanden",
    "MK": "Noord-Macedonië",
    "ML": "Mali",
    "MM": "Myanmar",
    "MN": "Mongolië",
    "MO": "Macau",
    "MP": "Noordelijke Marianen",
    "MQ": "Martinique",
    "MR": "Mauritanië",
    "MS": "Montserrat",
    "MT": "Malta",
    "MU": "Mauritius",
    "MV": "Malediven",
    "MW": "Malawi",
    "MX": "Mexico",
    "MY": "Maleisië",
    "MZ": "Mozambique",
    "NA": "Namibië",
    "NC": "Nieuw-Caledonië",
    "NE": "Niger",
    "NF": "Norfolk",
    "NG": "Nigeria",
    "NI": "Nicaragua",
    "NL": "Nederland",
    "NO": "Noorwegen",
    "NP": "Nepal",
    "NR": "Nauru",
    "NU": "Niue",
    "NZ": "Nieuw-Zeeland",
    "OM": "Oman",
    "PA": "Panama",
    "PE": "Peru",
    "PF": "Frans-Polynesië",
    "PG": "Papoea-Nieuw-Guinea",
    "PH": "Filipijnen",
    "PK": "Pakistan",
    "PL": "Polen",
    "PM": "Saint-Pierre en Miquelon",
    "PN": "Pitcairn",
    "PR": "Puerto Rico",
    "PS": "Palestina",
    "PT": "Portugal",
    "PW": "Palau",
    "PY": "Paraguay",
    "QA": "Qatar",
    "RE": "Réunion",
    "RO": "Roemenië",
    "RS": "Servië",
    "RU": "Rusland",
    "RW": "Rwanda",
    "SA": "Saoedi-Arabië",
    "SB": "Salomonseilanden",
    "SC": "Seychellen",
    "SD": "Soedan",
    "SE": "Zweden",
    "SG": "Singapore",
    "SH": "Sint-Helena, Ascension en Tristan da Cunha",
    "SI": "Slovenië",
    "SJ": "Spitsbergen en Jan Mayen",
    "SK": "Slowakije",
    "SL": "Sierra Leone",
    "SM": "San Marino",
    "SN": "Senegal",
    "SO": "Somalië",
    "SR": "Suriname",
    "SS": "Zuid-Soedan",
    "ST": "Sao Tomé en Principe",
    "SV": "El Salvador",
    "SX": "Sint Maarten",
    "SY": "Syrië",
    "SZ": "Eswatini",
    "TC": "Turks- en Caicoseilanden",
    "TD": "Tsjaad",
    "TF": "Franse Zuidelijke Gebieden",
    "TG": "Togo",
    "TH": "Thailand",
    "TJ": "Tadzjikistan",
    "TK": "Tokelau",
    "TL": "Oost-Timor",
    "TM": "Turkmenistan",
    "TN": "Tunesië",
    "TO": "Tonga",
    "TR": "Turkije",
    "TT": "Trinidad en Tobago",
    "TV": "Tuvalu",
    "TW": "Taiwan",
    "TZ": "Tanzania",
    "UA": "Oekraïne",
    "UG": "Oeganda",
    "UM": "Amerikaanse Kleinere Afgelegen Eilanden",
    "US": "Verenigde Staten",
    "UY": "Uruguay",
    "UZ": "Oezbekistan",
    "VA": "Vaticaanstad",
    "VC": "Saint Vincent en de Grenadines",
    "VE": "Venezuela",
    "VG": "Britse Maagdeneilanden",
    "VI": "Amerikaanse Maagdeneilanden",
    "VN": "Vietnam",
    "VU": "Vanuatu",
    "WF": "Wallis en Futuna",
    "WS": "Samoa",
    "YE": "Jemen",
    "YT": "Mayotte",
    "ZA": "Zuid-Afrika",
    "ZM": "Zambia",
    "ZW": "Zimbabwe"
  }
}