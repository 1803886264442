/* eslint-disable max-statements */
/* eslint-disable max-lines-per-function */
import { type Ref } from 'vue';

// eslint-disable-next-line max-lines-per-function, max-statements
export const usePathStore = defineStore('path', () => {
    // Data
    const step: Ref<number> = ref(-1);
    const bookerName: Ref<string> = ref('');
    const eventTypeId: Ref<number> = ref(0);
    const selectedPath: Ref<string> = ref('');
    const selectedDate: Ref<string> = ref('');
    const surveyOrganizer: Ref<string> = ref('');
    const surveyTheme: Ref<string> = ref('');
    const surveyAppointment: Ref<string> = ref('');
    const guestAmount: Ref<number> = ref(0);
    const descriptionIds: Ref<number[]> = ref([]);
    const budgetAmount: Ref<number> = ref(0);
    const appointment: Ref<string> = ref('');
    const appointmentDate: Ref<string> = ref('');

    const resetPathStore = (): void => {
        step.value = -1;
        bookerName.value = '';
        eventTypeId.value = 0;
        selectedPath.value = '';
        selectedDate.value = '';
        surveyOrganizer.value = '';
        surveyTheme.value = '';
        surveyAppointment.value = '';
        guestAmount.value = 0;
        descriptionIds.value = [];
        budgetAmount.value = 0;
        appointment.value = '';
        appointmentDate.value = '';
    };

    return {
        bookerName,
        step,
        eventTypeId,
        selectedPath,
        selectedDate,
        surveyOrganizer,
        surveyTheme,
        surveyAppointment,
        guestAmount,
        descriptionIds,
        budgetAmount,
        appointment,
        appointmentDate,
        resetPathStore,
    };
});
