<script setup lang="ts">
    import CircleLoader from '@shared/components/ui/circle-loader.vue';

    const props = defineProps<{
        step: number
    }>();

    const emit = defineEmits<{(event: 'next', value: number)}>();

    const { t } = useI18n();

    const ENTRY_DELAY = 100;
    const EXIT_DELAY = 1000;
    const STEP_MIDDLE = 0.5;
    const STEP_NEXT = 1;
    const WAITING_DELAY = 2000;

    const showElement = ref(false);
    const exitElement = ref(false);

    const handleExitStep = ():void => {
        exitElement.value = true;
        emit('next', props.step + STEP_MIDDLE);
        setTimeout(() => {
            emit('next', props.step + STEP_NEXT);
        }, EXIT_DELAY);
    };

    const resetAnimationState = ():void => {
        showElement.value = false;
        exitElement.value = false;

        // Trigger the "showElement" transition again after a brief delay
        setTimeout(() => {
            showElement.value = true;
        }, ENTRY_DELAY);
    };

    onMounted(() => {
        resetAnimationState();

        setTimeout(() => {
            handleExitStep();
        }, WAITING_DELAY);
    });
</script>

<template>
    <div
        :class="{
            'slide-bottom-up': showElement && !exitElement,
            'slide-out': exitElement
        }"
        class="loading-card"
    >
        <div class="loading-card__text">
            <h2 class="loading-card__title">
                {{ t('widget.loading.title') }}
            </h2>
            <span class="loading-card__subtitle">
                {{ t('widget.loading.subtitle') }}
            </span>
        </div>
        <CircleLoader
            :size="100"
            :thickness="7"
        />
    </div>
</template>

<style lang="scss" scoped>
.loading-card {
    position: absolute;
    display: flex;
    overflow: hidden;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    block-size: 100%;
    gap: var(--spacing-06);
    inline-size: 100%;
    inset-block-start: 0;
    transform: translateY(100%);
    transition: all 0.75s ease-in-out;

    h2 {
        font-size: var(--widget-font-h1);
        padding-inline: var(--spacing-03);
        text-align: center;
    }

    &__subtitle {
        font-family: var(--font-secondary);
        font-size: var(--widget-font-h3);
        font-weight: var(--font-weight-medium);
        line-height: 1.2;
        padding-inline: var(--spacing-03);
        text-align: center;
    }

    &__text {
        display: flex;
        flex-direction: column;
        align-items: center;
        gap: var(--spacing-03);
        max-inline-size: 400px;
    }

    &.slide-bottom-up {
        transform: translateY(0);
    }

    &.slide-out {
        transform: translateY(-100%);
    }
}
</style>
