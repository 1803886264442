<script setup lang="ts">
    import PackageItem from '@/components/PackageItem.ce.vue';
    import { type PackageTyping } from '@/typings/widget';
    import { ref } from 'vue';
    import { useI18n } from 'vue-i18n';

    const { t } = useI18n();

    const props = defineProps<{
        packages: PackageTyping[] | null,
    }>();

    // @todo these states are not working...
    const fetching = ref(false);
    const error = ref(false);

    defineEmits<{(event: 'packageSelected', value: PackageTyping)}>();

</script>

<template>
    <div class="package-gallery">
        <div v-if="props.packages">
            <ul class="package__list_declaration">
                <li
                    v-for="p in props.packages"
                    :key="String(p)"
                    class="package__list"
                >
                    <PackageItem
                        :package="p"
                        @click="$emit('packageSelected', p)"
                    />
                </li>
            </ul>
        </div>
        <div
            v-if="props.packages && props.packages.length === 0"
            class="no-package-results"
        >
            {{ t('widget.package-list.no-results') }}
        </div>
        <div v-if="fetching">
            {{ t('widget.global.loading') }}
        </div>
        <div v-if="error">
            {{ t('widget.global.error-wrong') }}
        </div>
    </div>
</template>

<style scoped lang="scss">

.package-gallery {
    .package__list_declaration {
        display: grid;
        grid-gap: var(--widget-base-gutter);
        grid-template-columns: repeat(auto-fill, minmax(270px, 1fr));
        justify-items: center;
        list-style-type: none;
        padding-block: var(--spacing-04);
        padding-inline: var(--spacing-03);

        @include min-width(md) {
            padding-block: var(--spacing-05);
            padding-inline: var(--spacing-05);
        }

        @include min-width(lg) {
            padding-inline: var(--spacing-06);
        }
    }

    .package__list {
        position: relative;
        border-radius: var(--border-radius-sm);
        background-color: var(--background-secondary);
    }
}

.no-package-results {
    padding: var(--widget-inner-gutter);
    font-size: var(--widget-font-regular);
    text-align: center;
}

</style>
