<template lang="html">
    <div class="side-bar" />
</template>

<style lang="scss" scoped>
    .side-bar {
        z-index: var(--zindex-overlay);
        display: none;
        background: var(--widget-brand-color);
        block-size: 100%;
        border-inline-start: 1px solid var(--border-tertiary);
        inline-size: var(--size-13);

        @include min-width(lg) {
            display: block;
        }
    }
</style>
