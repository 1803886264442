<!-- eslint-disable @typescript-eslint/no-magic-numbers -->
<script setup lang="ts">
    import '@/assets/images/arrangementen.png';
    import { getContrastColor } from '@/helpers/text';

    const props = defineProps<{
        modelValue: string
        step: number
        brandColor?: string
    }>();

    // eslint-disable-next-line func-call-spacing
    const emit = defineEmits<{
        (event: 'update:modelValue', value: string): void
        (event: 'next' | 'selected', value: number): void
        (event: 'selectPackages' | 'started'): void
    }>();

    const { t } = useI18n();

    const ENTRY_DELAY = 100;
    const EXIT_DELAY = 3000;
    const TRANSITION_DELAY = 0.1;
    const STEP_MIDDLE = 0.5;
    const STEP_NEXT = 1;
    const ARRENGEMENTEN_IMG = `${import.meta.env.VITE_CDN_URL}/assets/arrangementen.png`;

    const pathModel = ref(props.modelValue ?? '');
    const showElement = ref(false);
    const exitElement = ref(false);

    const cardItems = computed(() => [
        {
            value: 'quick',
            title: t('widget.selection.quick'),
            desc: t('widget.selection.quick-desc'),
            action: t('widget.selection.quick-action'),
            image: ARRENGEMENTEN_IMG,
        },
        {
            value: 'journey',
            title: t('widget.selection.journey'),
            desc: t('widget.selection.journey-desc'),
            action: t('widget.selection.journey-action'),
        },
    ]);

    const resetAnimationState = ():void => {
        showElement.value = false;
        exitElement.value = false;

        // Trigger the "showElement" transition again after a brief delay
        setTimeout(() => {
            showElement.value = true;
        }, ENTRY_DELAY);
    };

    const handleNextStep = (step: string): void => {
        pathModel.value = step;
        exitElement.value = true;

        const next = props.step + STEP_MIDDLE;

        emit('selected', pathModel.value === 'journey' ? next : -1);

        if (step === 'quick'){
            emit('selectPackages');
            return;
        }

        emit('next', next);

        setTimeout(() => {
            emit('next', props.step + STEP_NEXT);
        }, EXIT_DELAY);
    };

    onMounted(() => {
        resetAnimationState();
        emit('started');
    });

    watch(() => pathModel.value, (nVal: string) => {
        emit('update:modelValue', nVal);
    });
</script>

<template>
    <div
        :class="{'slide-up': exitElement}"
        class="path-selection"
    >
        <div
            v-for="(item, index) in cardItems"
            :id="item.value === 'journey' ? 'journey-path-card' : undefined"
            :key="item.value"
            :class="{
                'path-selection__card--brand-bg': item.value === 'journey',
                'slide-bottom-up': showElement,
                'fade-out': exitElement && item.value !== pathModel,
            }"
            :style="{ transitionDelay: showElement && !exitElement ? `${(index + 2) * TRANSITION_DELAY}s` : undefined }"
            class="path-selection__card"
            @click="handleNextStep(item.value)"
        >
            <img
                v-if="item.image"
                :src="item.image"
            >
            <div
                v-else
                :id="item.value === 'journey' ? 'venue-host-path' : undefined"
                class="path-selection__card__fallback-img"
            />
            <h3 :style="{ color: item.value === 'journey' ? getContrastColor(brandColor ?? '#282829') : undefined}">
                {{ item.title }}
            </h3>
            <span :style="{ color: item.value === 'journey' ? getContrastColor(brandColor ?? '#282829') : undefined}">{{ item.desc }}</span>
            <button
                :class="{'btn--brand-bg': item.value === 'journey'}"
                class="btn btn--thin path-selection__action"
                type="button"
            >
                {{ item.action }}
            </button>
        </div>
    </div>
</template>

<style lang="scss" scoped>
.path-selection {
    display: flex;
    overflow: hidden;
    flex-direction: column-reverse;
    align-items: center;
    justify-content: center;
    block-size: 100%;
    gap: var(--spacing-03);
    inline-size: 100%;
    padding-block: var(--spacing-03);
    padding-inline: var(--spacing-05);
    transition: all 0.75s ease-in-out;

    @include min-width(md) {
        flex-direction: row;
        align-items: center;
        justify-content: center;
    }

    &.slide-up {
        transform: translateY(-100%);
        transition-delay: 2s;
    }

    &__card {
        position: relative;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: space-between;
        border: 1px solid var(--color-white);
        border-radius: var(--border-radius-md);
        background: rgb(255 255 255 / 0.4);
        cursor: pointer;
        inline-size: 100%;
        max-inline-size: 100%;
        opacity: 0;
        padding-block: var(--spacing-05) var(--spacing-04);
        padding-inline: var(--spacing-04);
        transform: translateY(100%);
        transition:
        all 0.3s ease-in-out,
        transform 1s cubic-bezier(0,1,1,1);

        @include min-width(md) {
            max-inline-size: 258px;
            padding-block: var(--spacing-06) var(--spacing-05);
        }

        &:hover {
            background: var(--background-secondary-highlight);
        }

        &.slide-bottom-up {
            opacity: 1;
            transform: translateY(0);
        }

        &.fade-out {
            padding: 0;
            block-size: 0;
            inline-size: 0;
            opacity: 0;
            visibility: hidden;
        }

        img,
        &__fallback-img {
            position: relative;
            overflow: hidden;
            border-radius: var(--border-radius-circle);
            background: var(--background-secondary-highlight);
            block-size: 100px;
            inline-size: 100px;
            margin-block-end: var(--spacing-04);

            @include min-width(md) {
                block-size: 135px;
                inline-size: 135px;
            }
        }

        h3, span {
            text-align: center;
        }

        h3 {
            font-size: var(--widget-font-h3);
            margin-block-end: var(--spacing-02);

            @include min-width(md) {
                margin-block-end: var(--spacing-04);
            }
        }

        span {
            font-size: var(--widget-font-regular);
            margin-block-end: var(--spacing-03);

            @include min-width(md) {
                margin-block-end: var(--spacing-06);
            }
        }

        .btn {
            border: 1px solid var(--border-quaternary);
            border-radius: var(--border-radius-rounded);
            background-color: var(--background-secondary);

            &--brand-bg {
                background: var(--background-primary-invert);
                color: var(--text-primary-invert);
            }
        }

        &--brand-bg {
            background: var(--widget-brand-color);

            &:hover {
                background: var(--widget-brand-color);
                opacity: 0.9;
            }
        }
    }

    &__action {
        min-inline-size: 180px;
    }
}
</style>
