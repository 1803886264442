<script setup lang="ts">
    import AdvancedImage from '@shared/components/ui/advanced-image.vue';
    import { type MediaTyping } from '@shared/typings';

    const DEFAULT_AVATAR = 'https://constell.com/img/avatar.jpg';

    defineProps<{
        cover?: MediaTyping;
    }>();

    const emit = defineEmits<{(event: 'changed', value?: HTMLElement)}>();

    const intersectionObserver: Ref<IntersectionObserver|undefined> = ref();
    const mutationObserver: Ref<MutationObserver|undefined> = ref();
    const resizeObserver: Ref<ResizeObserver|undefined> = ref();
    const target: Ref<HTMLDivElement|undefined> = ref();
    const scrollableParent: Ref<HTMLElement|undefined|null> = ref();
    const transitioning: Ref<boolean> = ref(false);

    const logElementPosition = (): void => {
        emit('changed', target.value);
    };

    const getScrollableParent = (targetEl: HTMLElement | null): HTMLElement | null => {
        if (!targetEl) return null;
        const style = getComputedStyle(targetEl);
        const overflowRegex = /(?:auto|scroll)/u;
        if (overflowRegex.test(style.overflow + style.overflowY + style.overflowX)) {
            return targetEl;
        }
        return getScrollableParent(targetEl.parentElement);
    };

    const onMutationObserver: MutationCallback = (mutations): void => {
        mutations.forEach(_mutation => {
            logElementPosition();
        });
    };

    const trackTransition = (): void => {
        if (transitioning.value) {
            logElementPosition();
            requestAnimationFrame(trackTransition);
        } else {
            transitioning.value = false;
        }
    };

    const onTargetTransitionStarted = (): void => {
        transitioning.value = true;
        requestAnimationFrame(trackTransition);
    };

    const onTargetTransitionEnded = (): void => {
        transitioning.value = false;
    };

    const destroyTargetTransitionListener = (): void => {
        transitioning.value = false;
        if (target.value?.parentElement) {
            target.value?.parentElement.removeEventListener('transitionstart', onTargetTransitionStarted);
            target.value?.parentElement.removeEventListener('transitionend', onTargetTransitionEnded);
        }
    };

    const createTransitionListener = (): void => {
        if (target.value?.parentElement) {
            target.value?.parentElement.addEventListener('transitionstart', onTargetTransitionStarted);
            target.value?.parentElement.addEventListener('transitionend', onTargetTransitionEnded);
        }
    };

    const createScrollParentListener = (): void => {
        if (target.value) {
            scrollableParent.value = getScrollableParent(target.value);
            scrollableParent.value?.addEventListener('scroll', logElementPosition);
            scrollableParent.value?.addEventListener('resize', logElementPosition);
        }
    };

    const createIntersectionObserver = (): void => {
        if (target.value?.parentElement) {
            intersectionObserver.value = new IntersectionObserver(logElementPosition);
            intersectionObserver.value.observe(target.value?.parentElement);
        }
    };

    const createResizeObserver = (): void => {
        if (target.value?.parentElement) {
            resizeObserver.value = new ResizeObserver(logElementPosition);
            resizeObserver.value.observe(target.value.parentElement);
        }
    };

    const createMutationObserver = (): void => {
        if (target.value?.parentElement) {
            mutationObserver.value = new MutationObserver(onMutationObserver);
            mutationObserver.value?.observe(target.value.parentElement, { attributes: true });
        }
    };

    const destroyResizeObserver = (): void => {
        if (typeof resizeObserver.value?.disconnect === 'function') {
            resizeObserver.value.disconnect();
        }
    };

    const destroyMutationObserver = (): void => {
        if (typeof mutationObserver.value?.disconnect === 'function') {
            mutationObserver.value.disconnect();
        }
    };

    const destroyIntersectionObserver = (): void => {
        if (typeof intersectionObserver.value?.disconnect === 'function') {
            intersectionObserver.value.disconnect();
        }
    };

    const destroyScrollParentListener = (): void => {
        if (target.value && scrollableParent.value) {
            scrollableParent.value?.removeEventListener('scroll', logElementPosition);
            scrollableParent.value?.removeEventListener('resize', logElementPosition);
            scrollableParent.value = null;
        }
    };

    onMounted(() => {
        createScrollParentListener();
        createIntersectionObserver();
        createResizeObserver();
        createMutationObserver();
        createTransitionListener();
    });

    onBeforeUnmount(() => {
        destroyTargetTransitionListener();
        destroyResizeObserver();
        destroyMutationObserver();
        destroyIntersectionObserver();
        destroyScrollParentListener();
    });
</script>

<template>
    <div
        ref="target"
        class="venue-host-img"
    >
        <AdvancedImage
            aspect-ratio="1:1"
            :preview="cover ? null : DEFAULT_AVATAR"
            :media="cover"
        />
    </div>
</template>

<style lang="scss" scoped>
.venue-host-img {
    position: relative;
    overflow: hidden;
    border:  solid 1px var(--border-primary);
    border-radius: var(--size-2);
    aspect-ratio: 1/1;
    inline-size: 100%;
    transition: all 1s ease-in-out;
}
</style>
