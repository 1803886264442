/* stylelint-disable custom-property-pattern */
<!-- eslint-disable no-mixed-operators -->
<!-- eslint-disable @typescript-eslint/no-magic-numbers -->
<script setup lang="ts">
    import FloatingActionButton from '@/components/FloatingActionButton.ce.vue';
    import { type WidgetPositionEnum } from '@/constants/style';
    import IndexView from '@/views/index.ce.vue';
    import { FormKitRoot } from '@formkit/vue';
    import { ref } from 'vue';

    defineProps<{
        buttonLabel: string
        buttonLabelClose?: string
        venue: string
        locale: string
        position?: WidgetPositionEnum
        offsetX?: number
        offsetY?: number
        brandColor?: string
        maxGuestAmount?: number
        minGuestAmount?: number
        guestAmountStep?: number
        maxBudgetAmount?: number
        minBudgetAmount?: number
        budgetStep?: number
    }>();

    const { resetPathStore } = usePathStore();

    const openPackages = ref(false);

    const handleOpenWindow = (): void => {
        openPackages.value = !openPackages.value;

        if (openPackages.value) {
            resetPathStore();
        }
    };
</script>

<template>
    <div
        v-if="venue"
        class="widget-root"
        :class="[ [position]]"
        :style="{
            '--widget-offset-x': `${offsetX}px`,
            '--widget-offset-y': `${offsetY}px`,
            ...(brandColor && { '--widget-brand-color': brandColor }),
        }"
    >
        <FormKitRoot>
            <div
                v-if="openPackages"
                class="overlay"
            />
            <div class="floating-button">
                <FloatingActionButton
                    :open-state-label="buttonLabelClose"
                    :closed-state-label="buttonLabel"
                    :brand-color="brandColor"
                    @let-the-window-grow="handleOpenWindow"
                />
            </div>
            <div
                id="floating-window"
                class="floating-window"
                :class="[{ open: openPackages, close: !openPackages } ]"
            >
                <Transition name="fade">
                    <IndexView
                        v-show="openPackages"
                        :is-open="openPackages"
                        :venue="venue"
                        :locale="locale"
                        :initialized="openPackages"
                        :max-guest-amount="maxGuestAmount"
                        :min-guest-amount="minGuestAmount"
                        :guest-amount-step="guestAmountStep"
                        :max-budget-amount="maxBudgetAmount"
                        :min-budget-amount="minBudgetAmount"
                        :budget-step="budgetStep"
                        :brand-color="brandColor"
                    />
                </Transition>
            </div>
        </FormKitRoot>
    </div>
</template>

<style scoped lang="scss">
.overlay {
    position: fixed;
    z-index: var(--zindex-all-top);
    background: var(--color-black-space-opacity-64);
    block-size: 100%;
    inline-size: 100%;
    inset-block-start: 0;
    inset-inline-start: 0;
}

.floating-button {
    position: fixed;
    z-index: calc(var(--zindex-all-top) + 1);
}

.floating-window {
    position: fixed;
    z-index: calc(var(--zindex-all-top) + 1);
    overflow: hidden;
    border-radius: var(--border-radius-md);
    block-size: 0;
    box-shadow: 0 4px 124px 0 rgb(0 0 0 / 0.12);
    inline-size: 0;
    max-inline-size: var(--widget-max-width-mobile);
    opacity: 0;
    transform: scale(1);
    transition: opacity 1s cubic-bezier(0.2, 0.8, 0.2, 1);

    &.open {
        block-size: calc(var(--widget-height) - var(--widget-offset-y));
        inline-size: var(--widget-width);
        opacity: 1;
    }

    @include min-width(lg) {
        max-block-size: var(--widget-max-height);
        max-inline-size: var(--widget-max-width);
    }
}

// Positions

// eslint-disable-next-line vue-scoped-css/no-unused-selector, vue-scoped-css/require-selector-used-inside
.bottom-right {
    .floating-button {
        inset-block-end: calc(var(--widget-button-base-offset-y) + var(--widget-offset-y));
        inset-inline-end: calc(var(--widget-button-base-offset-x) + var(--widget-offset-x));
    }

    .floating-window {
        inset-block-end: calc(var(--widget-button-base-offset-y) + var(--widget-base-offset-y) + var(--size-11) + var(--widget-offset-y));
        inset-inline-end: var(--widget-button-base-offset-x);

        @include min-width(lg) {
            inset-block-end: calc(var(--widget-button-base-offset-y) + var(--widget-base-offset-y) + var(--size-11) + var(--widget-offset-y));
            inset-inline-end: calc(var(--widget-base-offset-x) + var(--widget-offset-x));
        }
    }
}

// eslint-disable-next-line vue-scoped-css/no-unused-selector, vue-scoped-css/require-selector-used-inside
.bottom-center {
    .floating-button {
        inset-block-end: calc(var(--widget-button-base-offset-y) + var(--widget-offset-y));
        inset-inline-start: 50%;
        transform: translateX(-50%);
    }

    .floating-window {
        inset-block-end: calc(var(--widget-button-base-offset-y) + var(--widget-base-offset-y) + var(--size-11) + var(--widget-offset-y));
        inset-inline-start: 50%;
        transform: translateX(-50%);

        @include min-width(md) {
            inset-block-end: calc(var(--widget-button-base-offset-y) + var(--widget-base-offset-y) + var(--size-11) + var(--widget-offset-y));
        }
    }
}

// eslint-disable-next-line vue-scoped-css/no-unused-selector, vue-scoped-css/require-selector-used-inside
.bottom-left {
    .floating-button {
        inset-block-end: calc(var(--widget-button-base-offset-y) + var(--widget-offset-y));
        inset-inline-start: calc(var(--widget-button-base-offset-x) + var(--widget-offset-x));
    }

    .floating-window {
        inset-block-end: calc(var(--widget-button-base-offset-y) + var(--widget-base-offset-y) + var(--size-11) + var(--widget-offset-y));
        inset-inline-start: var(--widget-button-base-offset-x);

        @include min-width(lg) {
            inset-block-end: calc(var(--widget-button-base-offset-y) + var(--widget-base-offset-y) + var(--size-11) + var(--widget-offset-y));
            inset-inline-start: calc(var(--widget-base-offset-x) + var(--widget-offset-x));
        }
    }
}

// eslint-disable-next-line vue-scoped-css/no-unused-selector, vue-scoped-css/require-selector-used-inside
.top-right {
    .floating-button {
        inset-block-start: calc(var(--widget-button-base-offset-y) + var(--widget-offset-y));
        inset-inline-end: calc(var(--widget-button-base-offset-x) + var(--widget-offset-x));
    }

    .floating-window {
        inset-block-start: calc(var(--widget-base-offset-y) + var(--widget-offset-y) + var(--widget-header-height));
        inset-inline-end: var(--widget-button-base-offset-x);

        @include min-width(lg) {
            inset-inline-end: calc(var(--widget-base-offset-x) + var(--widget-offset-x));
        }
    }
}

// eslint-disable-next-line vue-scoped-css/no-unused-selector, vue-scoped-css/require-selector-used-inside
.top-center {
    .floating-button {
        inset-block-start: calc(var(--widget-button-base-offset-y) + var(--widget-offset-y));
        inset-inline-start: 50%;
        transform: translateX(-50%);
    }

    .floating-window {
        inset-block-start: calc(var(--widget-base-offset-y) + var(--widget-offset-y) + var(--widget-header-height));
        inset-inline-start: 50%;
        transform: translateX(-50%);

        @include min-width(lg) {
            inset-block-start: calc(var(--widget-base-offset-y) + var(--widget-offset-y) + var(--widget-header-height));
        }
    }
}

// eslint-disable-next-line vue-scoped-css/no-unused-selector, vue-scoped-css/require-selector-used-inside
.top-left {
    .floating-button {
        inset-block-start: calc(var(--widget-button-base-offset-y) + var(--widget-offset-y));
        inset-inline-start: calc(var(--widget-button-base-offset-x) + var(--widget-offset-x));
    }

    .floating-window {
        inset-block-start: calc(var(--widget-base-offset-y) + var(--widget-offset-y) + var(--widget-header-height));
        inset-inline-start: var(--widget-button-base-offset-x);

        @include min-width(lg) {
            inset-inline-start: calc(var(--widget-base-offset-x) + var(--widget-offset-x));
        }
    }
}

:deep(.formkit-outer .formkit-inner button .formkit-selection-wrapper span),
:deep(.formkit-outer .formkit-inner .dropdown__list ul li .option span),
:deep(.formkit-outer .formkit-inner input),
:deep(.formkit-outer .formkit-inner input::placeholder),
:deep(.formkit-outer .formkit-inner textarea),
:deep(.formkit-outer .formkit-inner textarea::placeholder)  {
    /* stylelint-disable-next-line declaration-no-important */
    font-size: var(--widget-font-medium) !important;
}
</style>
