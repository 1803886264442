export const dateFormatStandard = 'DD-MM-YYYY';
export const dateFormatPretty = 'D MMMM YYYY';
export const dateFormatPrettyMinimizedYear = 'D MMMM, YY';
export const dateFormatPrettyFullDayMinimizedYear = 'dddd D MMMM, YY'; // Wednesday 12 May, 24
export const dateFormatCasual = 'ddd D MMM YY';
export const dateFormatCasualShort = 'ddd DD.MM.YY';
export const dateFormatCalendar = 'YYYY-MM-DD';
export const dateFormatFormKit = 'YYYY-MM-DD';
export const dateTimeFormatFormKit = 'YYYY-MM-DD HH:mm';
export const timeFormatStandard = 'HH:mm';
export const dateTimeSortingFormat = 'YYYYMMDDHHmm';
export const dateTimeStandard = 'DD-MM-YYYYHH:mm';
export const defaultTimeSlotHour = 9;

export enum DateTimeSortingEnum {
    DAY = 'day',
    WEEK = 'week',
    MONTH = 'month',
    YEAR = 'year'
}
