<script setup lang="ts">
    import Icon from '@shared/components/ui/icon/icon.vue';
    import { DateTime } from '@shared/helpers';
    import { type ComputedRef, type Ref } from 'vue';

    const props = defineProps<{ modelValue: string }>();
    const emit = defineEmits<{(event: 'update:modelValue', value: string | null)}>();

    const CURRENT_DATE = DateTime.dayjsObject();

    // Refs
    const calendarModel: Ref<string> = ref(props.modelValue ?? '');
    const currentMonthIndex = ref(CURRENT_DATE.month());
    const currentYear = ref(CURRENT_DATE.year());

    // Computed
    const daysInMonth: ComputedRef<number[]> = computed(() => {
        const days: number[] = [];
        const daysCount = DateTime.dayjsObject()
            .year(currentYear.value)
            .month(currentMonthIndex.value)
            .daysInMonth();
        for (let i = 1; i <= daysCount; i++) {
            days.push(i);
        }
        return days;
    });

    const emptyDays: ComputedRef<string[]> = computed(() => {
        const firstDayOfMonth = DateTime.dayjsObject()
            .year(currentYear.value)
            .month(currentMonthIndex.value)
            .startOf('month')
            .day();
        return Array.from({ length: firstDayOfMonth }, () => '');
    });

    const currentMonth: ComputedRef<string> = computed(() => DateTime.dayjsObject()
        .year(currentYear.value)
        .month(currentMonthIndex.value)
        .format('MMMM'));

    const isDateDisabled: ComputedRef<(date: number) => boolean> = computed(() => (date: number) => {
        const dateToCheck = DateTime.dayjsObject()
            .year(currentYear.value)
            .month(currentMonthIndex.value)
            .date(date);
        return dateToCheck.isBefore(CURRENT_DATE, 'day');
    });

    // Methods
    const selectDate = (date: number):string => {
        calendarModel.value = DateTime.dayjsObject()
            .year(currentYear.value)
            .month(currentMonthIndex.value)
            .date(date)
            .format('YYYY-MM-DD');

        return calendarModel.value;
    };

    const dateToString = (date: number):string => DateTime.dayjsObject()
        .year(currentYear.value)
        .month(currentMonthIndex.value)
        .date(date)
        .format('YYYY-MM-DD');

    const nextMonth = ():void => {
        const next = DateTime.dayjsObject()
            .year(currentYear.value)
            .month(currentMonthIndex.value)
            .add(1, 'month');
        currentMonthIndex.value = next.month();
        currentYear.value = next.year();
    };

    const prevMonth = ():void => {
        const prev = DateTime.dayjsObject()
            .year(currentYear.value)
            .month(currentMonthIndex.value)
            .subtract(1, 'month');
        currentMonthIndex.value = prev.month();
        currentYear.value = prev.year();
    };

    watch(() => calendarModel.value, (nVal: string) => {
        emit('update:modelValue', nVal);
    });
</script>

<template>
    <div class="calendar">
        <div class="calendar__header">
            <h2 class="calendar__title">
                {{ currentMonth }} {{ currentYear }}
            </h2>
            <div class="calendar__actions">
                <button
                    type="button"
                    class="calendar__nav-button btn btn--icon"
                    @click="prevMonth"
                >
                    <Icon
                        name="arrowLeft"
                        size="large"
                    />
                </button>
                <button
                    type="button"
                    class="calendar__nav-button btn btn--icon"
                    @click="nextMonth"
                >
                    <Icon
                        name="arrowRight"
                        size="large"
                    />
                </button>
            </div>
        </div>
        <div class="calendar__grid">
            <div
                v-for="(_, index) in emptyDays"
                :key="`empty-${index}`"
                class="calendar__cell calendar__cell--empty"
            />
            <div
                v-for="date in daysInMonth"
                :key="date"
                class="calendar__cell"
                :class="{
                    'calendar__cell--selected': dateToString(date) === calendarModel,
                    'calendar__cell--disabled': isDateDisabled(date)
                }"
                @click="() => !isDateDisabled(date) && selectDate(date)"
            >
                {{ date.toString().padStart(2, '0') }}
            </div>
        </div>
    </div>
</template>

<style scoped lang="scss">
.calendar {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: var(--spacing-05);

    &__header {
        display: flex;
        align-items: center;
        justify-content: space-between;
        inline-size: 100%;
    }

    &__title {
        margin: 0;
        font-size: var(--widget-font-h1);
        white-space: nowrap;
    }

    &__actions {
        display: flex;
        align-items: center;
        justify-content: flex-end;
    }

    &__nav-button {
        border: none;
        background: none;
        cursor: pointer;
        font-size: 1.5rem;
    }

    &__grid {
        display: grid;
        gap: 0.25rem;
        grid-template-columns: repeat(7, 1fr);

        @include min-width(md) {
            gap: 0.5rem;
        }
    }

    &__cell {
        display: flex;
        align-items: center;
        justify-content: center;
        border: 1px solid var(--color-white);
        border-radius: var(--border-radius-sm);
        background-color: rgb(255 255 255 / 0.40);
        block-size: var(--size-8);
        cursor: pointer;
        font-size: var(--widget-font-regular);
        inline-size: var(--size-8);
        transition: background-color 0.3s, border-color 0.3s;

        @include min-width(md) {
            block-size: var(--size-12);
            inline-size: var(--size-12);
        }

        &:hover:not(.calendar__cell--disabled) {
            background-color: var(--background-secondary-highlight);
        }

        &--selected {
            border-color: var(--border-primary);
            background-color: var(--color-white);
            font-weight: var(--font-weight-medium);
        }

        &--disabled {
            border-color: var(--border-disabled-1);
            background-color: var(--background-disabled-1);
            color: var(--text-disabled-1);
            cursor: not-allowed;
        }

        &--empty {
            visibility: hidden;
        }
    }

    @include min-width(lg) {
        gap: var(--spacing-03);
    }
}
</style>
