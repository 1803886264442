<!-- eslint-disable @typescript-eslint/no-magic-numbers -->
<script setup lang="ts">
    import WidgetCalendar from '@/components/WidgetCalendar.ce.vue';

    const props = defineProps<{
        modelValue: string;
        step: number
    }>();

    // eslint-disable-next-line func-call-spacing
    const emit = defineEmits<{
        (event: 'update:modelValue', value: string): void
        (event: 'next', value: number): void
        (event: 'started' | 'exited'): void
    }>();

    const { t } = useI18n();

    const ENTRY_DELAY = 100;
    const EXIT_DELAY = 1000;
    const STEP_MIDDLE = 0.5;
    const STEP_NEXT = 2;

    const calendarModel = ref(props.modelValue ?? '');
    const showElement = ref(false);
    const exitElement = ref(false);

    const handleExitStep = ():void => {
        exitElement.value = true;
        emit('next', props.step + STEP_MIDDLE);
        emit('exited');
        setTimeout(() => {
            emit('next', props.step + STEP_NEXT);
        }, EXIT_DELAY);
    };

    const resetAnimationState = ():void => {
        showElement.value = false;
        exitElement.value = false;

        // Trigger the "showElement" transition again after a brief delay
        setTimeout(() => {
            showElement.value = true;
            emit('started');
        }, ENTRY_DELAY);
    };

    onMounted(() => {
        resetAnimationState();
    });

    watch(() => calendarModel.value, (nVal: string) => {
        emit('update:modelValue', nVal);
    });
</script>

<template>
    <div
        :class="{
            'slide-bottom-up': showElement && !exitElement,
            'slide-out': exitElement
        }"
        class="calendar-card"
    >
        <div class="calendar-card__date">
            <WidgetCalendar v-model="calendarModel" />
            <div class="calendar-card__actions">
                <button
                    type="button"
                    class="btn btn--primary btn--thin calendar-card__action"
                    :class="{ 'btn--hidden': !calendarModel }"
                    :disabled="!calendarModel"
                    @click="handleExitStep"
                >
                    {{ t('widget.buttons.continue') }}
                </button>
            </div>
        </div>
    </div>
</template>

<style lang="scss" scoped>
.calendar-card {
    position: absolute;
    display: flex;
    overflow: hidden;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    block-size: 100%;
    gap: var(--spacing-03);
    inline-size: 100%;
    inset-block-start: 0;
    inset-inline-start: 50%;
    max-inline-size: 468px;
    transform: translate3d(-50%, 100%, 0);
    transition: all 0.75s ease-in-out;

    &__date {
        display: flex;
        flex: 1;
        flex-direction: column;
        align-items: center;
        justify-content: flex-start;
        block-size: 100%;
        gap: var(--spacing-03);
        inline-size: 100%;
        min-block-size: 100%;
        padding-block-start: var(--spacing-05);
        transform: translateY(0);

        @include min-width(lg) {
            position: relative;
        }
    }

    &__actions {
        position: absolute;
        display: flex;
        justify-content: center;
        inline-size: 100%;
        inset-block-end: var(--spacing-10);
        inset-inline-end: 0;
        padding-block: var(--spacing-03);
        padding-inline: var(--spacing-03);

        @include min-width(md) {
            padding-inline: 0;
        }

        @include min-width(lg) {
            justify-content: flex-end;
            inline-size: auto;
            inset-block-end: var(--spacing-06);
        }

        .btn--hidden {
            visibility: hidden;
        }
    }

    &__action {
        min-inline-size: 180px;
    }

    &.slide-bottom-up {
        transform: translate3d(-50%, 0, 0);
    }

    &.slide-out {
        transform: translate3d(-50%, -100%, 0);
        transition-delay: 0s;
    }
}
</style>
