<!-- eslint-disable @typescript-eslint/no-magic-numbers -->
<script setup lang="ts">
    import WidgetRangeInput from '@/components/WidgetRangeInput.ce.vue';

    const props = defineProps<{
        modelValue: number
        name: string
        step: number
        maxGuestAmount?: number
        minGuestAmount?: number
        guestAmountStep?: number
        event: string
    }>();

    // eslint-disable-next-line func-call-spacing
    const emit = defineEmits<{
        (event: 'update:modelValue' | 'next', value: number): void
        (event: 'started'): void
    }>();

    const { t } = useI18n();

    const ENTRY_DELAY = 100;
    const EXIT_DELAY = 1000;
    const STEP_MIDDLE = 0.5;
    const STEP_NEXT = 1;

    const amountModel = ref(props.modelValue ?? 0);
    const showTitle = ref(false);
    const showSubtitle = ref(false);
    const showElement = ref(false);
    const exitElement = ref(false);

    const handleExitStep = ():void => {
        exitElement.value = true;
        emit('next', props.step + STEP_MIDDLE);
        setTimeout(() => {
            emit('next', props.step + STEP_NEXT);
        }, EXIT_DELAY);
    };

    const resetAnimationState = (): void => {
        const delays = [
            {
                ref: showTitle,
                delay: ENTRY_DELAY,
            },
            {
                ref: showSubtitle,
                delay: EXIT_DELAY * 2,
            },
            {
                ref: showElement,
                delay: EXIT_DELAY * 4,
            },
        ];

        showTitle.value = false;
        showSubtitle.value = false;
        showElement.value = false;
        exitElement.value = false;

        delays.forEach(({ ref, delay }, index) => {
            setTimeout(() => {
                if (index === 2) emit('started');
                ref.value = true;
            }, delay);
        });
    };

    onMounted(() => {
        resetAnimationState();
    });

    watch(() => amountModel.value, (nVal: number) => {
        emit('update:modelValue', nVal);
    });
</script>

<template>
    <div
        :class="{
            'slide-next-up': showTitle && !exitElement,
            'slide-up': showSubtitle,
            'slide-up-final': showElement,
            'slide-out': exitElement
        }"
        class="amount-card"
    >
        <div class="amount-card__container">
            <h2>{{ t('widget.amount.great', { name: name }) }}</h2>
        </div>
        <div class="amount-card__container">
            <h2>{{ t('widget.amount.now') }}</h2>
        </div>
        <div class="amount-card__container">
            <h3>{{ t('widget.amount.how-many', { event }) }}</h3>
            <WidgetRangeInput
                v-model="amountModel"
                :min="minGuestAmount ?? 0"
                :max="maxGuestAmount ?? 1000"
                :step="guestAmountStep ?? 1"
            />
            <button
                :class="{'btn--secondary': !amountModel, 'btn--primary': amountModel}"
                :disabled="!amountModel"
                type="button"
                class="btn btn--thin amount-card__action"
                @click="handleExitStep"
            >
                {{ t('widget.buttons.next') }}
            </button>
        </div>
    </div>
</template>

<style lang="scss" scoped>
.amount-card {
    position: absolute;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    block-size: 100%;
    inline-size: 100%;
    inset-block-start: 0;
    transform: translateY(100%);
    transition: all 0.75s ease-in-out;

    h2 {
        font-size: var(--widget-font-h1);
        padding-inline: var(--spacing-03);
        text-align: center;
    }

    h3 {
        font-size: var(--widget-font-h2);
        text-align: center;
    }

    &__container {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        block-size: 100%;
        inline-size: 100%;
        min-block-size: 100%;
    }

    &__action {
        margin-block-start: var(--spacing-06);
        min-inline-size: 180px;
    }

    &.slide-next-up {
        transform: translateY(0);
    }

    &.slide-up {
        transform: translateY(-100%);
    }

    &.slide-up-final {
        transform: translateY(-200%);
        transition: all 1s ease-in-out;
    }

    &.slide-out {
        transform: translateY(-300%);
        transition: all 0.75s ease-in-out;
    }
}
</style>
