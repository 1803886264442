<!-- eslint-disable @typescript-eslint/no-magic-numbers -->
<script setup lang="ts">
    import WidgetRangeInput from '@/components/WidgetRangeInput.ce.vue';

    const props = defineProps<{
        modelValue: number
        step: number
        maxBudgetAmount?: number
        minBudgetAmount?: number
        budgetStep?: number
    }>();

    const emit = defineEmits<{(event: 'update:modelValue' | 'next', value: number)}>();

    const { t } = useI18n();

    const ENTRY_DELAY = 100;
    const EXIT_DELAY = 1000;
    const STEP_MIDDLE = 0.5;
    const STEP_NEXT = 1;

    const budgetModel = ref(props.modelValue ?? 0);
    const showElement = ref(false);
    const exitElement = ref(false);

    const handleExitStep = ():void => {
        exitElement.value = true;
        emit('next', props.step + STEP_MIDDLE);
        setTimeout(() => {
            emit('next', props.step + STEP_NEXT);
        }, EXIT_DELAY);
    };

    const resetAnimationState = (): void => {
        const delays = [
            {
                ref: showElement,
                delay: ENTRY_DELAY,
            },
        ];

        showElement.value = false;
        exitElement.value = false;

        delays.forEach(({ ref, delay }) => {
            setTimeout(() => {
                ref.value = true;
            }, delay);
        });
    };

    onMounted(() => {
        resetAnimationState();
    });

    watch(() => budgetModel.value, (nVal: number) => {
        emit('update:modelValue', nVal);
    });
</script>

<template>
    <div
        :class="{
            'slide-up': showElement && !exitElement,
            'slide-out': exitElement
        }"
        class="budget-card"
    >
        <div class="budget-card__container">
            <h3>{{ t('widget.budget.title') }}</h3>
            <WidgetRangeInput
                v-model="budgetModel"
                :min="minBudgetAmount ?? 0"
                :max="maxBudgetAmount ?? 25000"
                :step="budgetStep ?? 100"
                currency
            />
            <button
                :class="{'btn--secondary': !budgetModel, 'btn--primary': budgetModel}"
                :disabled="!budgetModel"
                type="button"
                class="btn btn--thin budget-card__action"
                @click="handleExitStep"
            >
                {{ t('widget.buttons.next') }}
            </button>
        </div>
    </div>
</template>

<style lang="scss" scoped>
.budget-card {
    position: absolute;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    block-size: 100%;
    inline-size: 100%;
    inset-block-start: 0;
    transform: translateY(100%);
    transition: all 0.75s ease-in-out;

    h3 {
        font-size: var(--widget-font-h2);
        padding-inline: var(--spacing-03);
        text-align: center;
    }

    &__container {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        block-size: 100%;
        inline-size: 100%;
        min-block-size: 100%;
    }

    &__action {
        margin-block-start: var(--spacing-06);
        min-inline-size: 180px;
    }

    &.slide-up {
        transform: translateY(0);
    }

    &.slide-out {
        transform: translateY(-100%);
        transition: all 0.75s ease-in-out;
    }
}
</style>
