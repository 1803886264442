<script setup lang="ts">
    import '@/assets/images/illustration-handcup.svg';
    import { DESCRIPTIONS } from '@/constants/descriptions';
    import { SURVEY_APPOINTMENT, SURVEY_ORGANIZER, SURVEY_THEME } from '@/constants/survey';
    import formKitConfig from '@/formkit';
    import { formatCurrencyNoCent } from '@/helpers/formatting';
    import { type EventTypeTyping } from '@/typings/widget';
    import { type FormKitNode } from '@formkit/core';
    import { defaultConfig, FormKitProvider, FormKitRoot } from '@formkit/vue';
    import DatePicker from '@shared/components/form/date-picker.vue';
    import Email from '@shared/components/form/email.vue';
    import PhoneNumber from '@shared/components/form/phone-number-input.vue';
    import TextInput from '@shared/components/form/text-input.vue';
    import TextArea from '@shared/components/form/textarea.vue';
    import { dayjsObject } from '@shared/helpers/date-time';
    import axios from 'axios';

    const props = defineProps<{
        venue: string
        name: string
        date: string
        locale: string
        eventType: number
        guestAmount?: number
        eventTypes: EventTypeTyping[]
        messageData?: {
            surveyOrganizer: string
            surveyTheme: string
            surveyAppointment: string
            descriptionIds: number[]
            budgetAmount: number
            appointmentDate: string
        }
    }>();

    const emit = defineEmits<{(event: 'processRequest', type: unknown)}>();

    const { t } = useI18n();

    const HANDCUP_IMG = `${import.meta.env.VITE_CDN_URL}/assets/illustration-handcup.svg`;

    // Refs
    const form = ref(null);
    const formData: Ref<{
        name: string
        surname: string
        eventDate: string
        companyName: string | null
        email: string | null
        phoneNumber: string | null
        message: string
        phoneCountryPrefix: string | null
        guestAmount: number | string | null
        eventTypeId: number | null
    }> = ref({
        name: props.name ?? '',
        surname: '',
        eventDate: props.date ?? '',
        companyName: null,
        email: null,
        phoneNumber: null,
        message: '',
        phoneCountryPrefix: '+31',
        guestAmount: props.guestAmount ?? null,
        eventTypeId: props.eventType ?? null,
    });

    // Computed
    const eventName = computed(() => props.eventTypes.find(event => Number(event.id) === Number(props.eventType))?.name ?? '');
    const organizationType = computed(() => t(SURVEY_ORGANIZER.find(org => org.value === props.messageData?.surveyOrganizer)?.label ?? ''));
    const flexibility = computed(() => t(SURVEY_APPOINTMENT.find(app => app.value === props.messageData?.surveyAppointment)?.label ?? ''));
    const theme = computed(() => t(SURVEY_THEME.find(thm => thm.value === props.messageData?.surveyTheme)?.label ?? ''));
    const descriptions = computed(() =>
        props.messageData?.descriptionIds
            .map(id => t(DESCRIPTIONS.find(desc => desc.id === id)?.name ?? ''))
            .join(', ') ?? '');
    const budget = computed(() => formatCurrencyNoCent(
        Number(props.messageData?.budgetAmount), 'EUR', props.locale,
    ));
    const appointmentDate = computed(() => props.messageData?.appointmentDate
        ? dayjsObject(props.messageData?.appointmentDate)
            .format('DD MMMM, YYYY')
        : '');

    // Methods
    const messageBuilder = (): string => {
        let message = '';
        if (props.messageData && props.eventType) {
            message = `
                - Event type: ${eventName.value}\n\n
                - Organization type: ${organizationType.value}\n\n
                - Theme: ${theme.value}\n\n
                - Flexibility: ${flexibility.value}\n\n
                - Descriptions: ${descriptions.value}\n\n
                - Budget: ${budget.value}\n\n
                - Appointment Date: ${appointmentDate.value}\n\n
            `;
        }
        return message;
    };

    const processForm = async(submittedData: typeof formData.value, formNode: FormKitNode): Promise<void> => {
        const payload = {
            ...submittedData,
            userName: `${submittedData.name} ${submittedData.surname}`,
            message: messageBuilder() + submittedData.message,
            name: null,
            surname: null,
        };

        try {
            const response = await axios
                .post(
                    `${import.meta.env.VITE_API_BASE_URL}/widget/api/venue/${props.venue}/quote`, payload, {
                        headers: {
                            'Content-Type': 'multipart/form-data',
                            'Accept-Language': props.locale,
                        },
                    },
                );
            emit('processRequest', response.data);
        } catch (error) {
            // eslint-disable-next-line no-console
            console.error('Posting request form failed: ', error);
            // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
            const msg = t('widget.messages.errors.request-failed');
            formNode.setErrors([msg]);
        }
    };
</script>

<template>
    <div class="results-form">
        <div class="results-form__info">
            <span>
                {{ t('widget.results.form.desc') }}
            </span>
            <img :src="HANDCUP_IMG">
        </div>
        <div class="results-form__form">
            <h2>
                {{ t('widget.results.form.title') }}
            </h2>
            <span>
                {{ t('widget.results.form.desc') }}
            </span>

            <FormKitProvider :config="defaultConfig(formKitConfig)">
                <FormKitRoot>
                    <FormKit
                        id="results-form"
                        ref="form"
                        v-model="formData"
                        :incomplete-message="false"
                        :submit-label="t('widget.buttons.send')"
                        :submit-attrs="{
                            inputClass: 'btn btn--primary btn--thin',
                            wrapperClass: 'im-on-the-wrapper',
                            outerClass: 'book-it-btn'
                        }"
                        type="form"
                        @submit="processForm"
                    >
                        <div class="results-form__form__content">
                            <div class="results-form__form__content__row">
                                <TextInput
                                    id="name"
                                    :placeholder="t('widget.form.your-name')"
                                    :validation-messages="{
                                        required: `${t('widget.form.validations.required', { field: t('widget.form.your-name') })}`
                                    }"
                                    name="name"
                                    validation="required"
                                />
                                <TextInput
                                    id="surname"
                                    :placeholder="t('widget.form.your-surname')"
                                    :validation-messages="{
                                        required: `${t('widget.form.validations.required', { field: t('widget.form.your-surname') })}`
                                    }"
                                    name="surname"
                                    validation="required"
                                />
                            </div>
                            <DatePicker
                                id="eventDate"
                                :placeholder="t('widget.form.event-date')"
                                :validation-messages="{
                                    required: `${t('widget.form.validations.required', { field: t('widget.form.event-date') })}`
                                }"
                                name="eventDate"
                                validation="required"
                            />
                            <TextInput
                                id="companyName"
                                :placeholder="t('widget.form.company-name')"
                                name="companyName"
                            />
                            <Email
                                id="email"
                                :placeholder="t('widget.form.email')"
                                :validation-messages="{
                                    required: `${t('widget.form.validations.required', { field: t('widget.form.email') })}`
                                }"
                                name="email"
                                validation="required"
                            />
                            <PhoneNumber
                                id="phoneNumber"
                                :validation-messages="{
                                    required: `${t('widget.form.validations.required', { field: t('widget.form.phone-number') })}`
                                }"
                                name="phoneNumber"
                                validation="required"
                                placeholder="0 000 000 00"
                            />
                            <TextArea
                                id="message"
                                :placeholder="t('widget.form.description')"
                                name="message"
                            />
                        </div>
                    </FormKit>
                </FormKitRoot>
            </FormKitProvider>
        </div>
    </div>
</template>

<style lang="scss" scoped>
.results-form {
    display: flex;
    justify-content: space-between;
    gap: var(--spacing-05);
    padding-inline: var(--spacing-03);

    @include min-width(md) {
        padding-inline: var(--spacing-08);
    }

    &__info {
        display: flex;
        flex: 1;
        flex-direction: column;
        align-items: flex-start;
        justify-content: flex-start;
        gap: var(--spacing-05);
        margin-block-start: var(--spacing-07);

        @include max-width(md) {
            display: none;
        }

        img {
            max-block-size: 170px;
        }

        span {
            font-size: var(--widget-font-regular);
        }
    }

    &__form {
        display: flex;
        flex: 1.5;
        flex-direction: column;
        gap: var(--spacing-03);

        @include min-width(md) {
            gap: var(--spacing-05);
        }

        h2 {
            font-size: var(--widget-font-h2);
            text-align: center;
        }

        span {
            display: block;
            font-size: var(--widget-font-regular);
            text-align: center;

            @include min-width(md) {
                display: none;
            }
        }

        &__content {
            display: flex;
            flex-direction: column;
            gap: var(--spacing-03);

            &__row {
                display: flex;
                justify-content: space-between;
                gap: var(--spacing-02);
            }
        }

        :deep(.formkit-actions) {
            .book-it-btn {
                display: flex;
                justify-content: flex-end;
                margin-block-start: var(--spacing-03);

                .formkit-wrapper {
                    @include max-width(md) {
                        inline-size: 100%;
                    }
                }

                button {
                    color: var(--text-primary-invert);
                    font-size: var(--widget-font-medium);
                    font-weight: var(--font-weight-medium);
                    inline-size: 100%;
                    letter-spacing: var(--letter-spacing-0);
                    line-height: var(--line-height-150);

                    @include min-width(md) {
                        inline-size: auto;
                    }
                }

            }
        }
    }
}
</style>
