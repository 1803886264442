<script setup lang="ts">
    import defaultImage from '@/assets/images/empty-image.svg';
    import { formatCurrency, totalHours } from '@/helpers/formatting';
    import { type PackageTyping } from '@/typings/widget';
    import PackageProgramme from '@/views/PackageProgramme.ce.vue';
    import AdvancedImage from '@shared/components/ui/advanced-image.vue';
    import { type Ref } from 'vue';

    const props = defineProps<{
        packageItem: PackageTyping
    }>();

    defineEmits<{(event: 'showBookView') }>();

    const { t, locale } = useI18n();

    const packageDescription: Ref<HTMLElement | null> = ref(null);

    const descriptionFormatter = (): void => {
        if (packageDescription.value) {
            const description = props.packageItem.description.replace(/\n\n/gu, '<br><br>');
            packageDescription.value.innerHTML = description;
        }
    };

    onMounted(() => {
        descriptionFormatter();
    });
</script>

<template>
    <div class="package-item-view">
        <div class="package-item-img">
            <AdvancedImage
                v-if="packageItem.cover"
                :media="packageItem.cover"
                :preview="defaultImage"
                :asset-width="850"
                no-crop
                aspect-ratio="2:1"
            />
            <img
                v-else
                :src="defaultImage"
            >
            <div class="item-header-description-hours">
                {{ totalHours(packageItem.programme) }}
            </div>
        </div>
        <div class="item-content-container">
            <div class="item-header">
                <h2 class="item-title">
                    {{ packageItem.name }}
                </h2>
                <div class="item-header-description">
                    <div
                        ref="packageDescription"
                        class="item-header-description-text"
                    />
                </div>
                <div class="item-header-price">
                    <h4 class="per-person-price">
                        {{ t('widget.global.from') }} {{ formatCurrency(packageItem.pricePerPerson, packageItem.currency, locale) }}
                    </h4>
                    <div class="item-header-price-att">
                        {{ t('widget.global.guest') }}
                    </div>
                </div>
            </div>
            <hr v-if="packageItem?.programme?.length">
            <div class="timeslots-container">
                <PackageProgramme
                    v-if="packageItem.programme[0]?.genericTimeslot
                        && (packageItem.programme[0]?.genericTimeslot?.description
                            || packageItem.programme[0]?.genericTimeslot?.products?.length
                            || packageItem.programme[0]?.genericTimeslot?.spaces?.length)"
                    :time-slot="packageItem.programme[0]?.genericTimeslot"
                    is-generic-timeslot
                />

                <template
                    v-for="timeSlot in packageItem.programme[0]?.timeslots"
                    :key="timeSlot?.id"
                >
                    <PackageProgramme
                        :time-slot="timeSlot"
                        :is-generic-timeslot="false"
                    />
                </template>
            </div>
            <hr v-if="packageItem.programme.length">
        </div>
        <div class="book-it-btn">
            <button
                type="button"
                class="btn btn--primary"
                @click="$emit('showBookView')"
            >
                <span>
                    {{ t('widget.buttons.book-it') }}
                </span>
            </button>
        </div>
    </div>
</template>

<style scoped lang="scss">
.package-item-view {
    padding-block: 0;
    padding-inline: var(--widget-inner-gutter);

    .package-item-img {
        position: relative;
        margin-inline: calc(-1 * var(--widget-inner-gutter));

        @include min-width(md) {
            margin-inline: 0;
        }

        :deep(img) {
            inline-size: 100%;
            object-fit: cover;

            @include min-width(md) {
                border-radius: 5px;
            }
        }

        .item-header-description-hours {
            position: absolute;
            display: inline-block;
            border-radius: 5px;
            background-color: var(--background-primary);
            inset-block-end: 21px;
            inset-inline-end: 17px;
            padding-block: 5px;
            padding-inline: 8px;
        }
    }

    .item-content-container {
        padding: var(--spacing-2x);
        padding-block-end: var(--spacing-06);

        @include min-width(md) {
            padding: var(--spacing-2x);
        }
    }

    .item-header {
        .item-title {
            font: 500 18px var(--font-primary);
        }

        .item-header-description {
            display: flex;
            flex-direction: row;
            justify-content: space-between;

            .item-header-description-text {
                align-self: center;
                color: var(--text-secondary);
                font-size: 15px;
                font-weight: var(--font-weight-light);
                padding-block-start: var(--spacing-2x);
            }
        }

        .item-header-price {
            display: flex;
            flex-direction: row;
            align-items: center;
            padding-block-start: 25px;

            .per-person-price {
                font: 500 18px var(--font-primary);
            }

            .item-header-price-att {
                color: var(--text-secondary);
                font-size: 14px;
                margin-inline-start: 7px;
            }
        }
    }

    hr {
        border: none;
        background: var(--border-tertiary);
        block-size: 1px;
        margin-block: 32px 0;
        margin-inline: 0;

        &:last-of-type {
            margin-block: 0 32px;
            margin-inline: 0;
        }
    }

    .book-it-btn {
        position: fixed;
        z-index: 100;
        inset-block-end: var(--spacing-05);
        inset-inline-end: 50%;
        transform: translateX(50%);

        @include min-width(md) {
            inset-inline-end: var(--spacing-05);
            transform: initial;
        }

        @include min-width(lg) {
            inset-inline-end: var(--spacing-06);
        }
    }
}
</style>
