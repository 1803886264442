<script setup lang="ts">
    import CustomTitle from '@/components/CustomTitle.ce.vue';
    import PackageSelection from '@/components/PackageSelection.ce.vue';
    import SideBar from '@/components/SideBar.ce.vue';
    import VenueHost from '@/components/VenueHost.ce.vue';
    import WelcomeScreen from '@/components/WelcomeScreen.ce.vue';
    import { VENUE_HOST_EVENT, VENUE_HOST_RESET } from '@/constants/events';
    import { type EventBusType } from '@/plugins/event-bus-plugin';
    import axios from 'axios';
    import PathView from './PathView.ce.vue';

    const props = defineProps<{
        venue: string
        locale: string
        initialized: boolean
        maxGuestAmount?: number
        minGuestAmount?: number
        guestAmountStep?: number
        maxBudgetAmount?: number
        minBudgetAmount?: number
        budgetStep?: number
        isOpen?: boolean
        brandColor?: string
    }>();

    const { t } = useI18n();

    const eventBus = inject<EventBusType>('eventBus');

    const { venue: venueData, venueEventTypes } = storeToRefs(useWidgetVenueStore());

    // Refs
    const mainViewContainer: Ref<HTMLElement | null> = ref(null);
    const packagesContainer:Ref<HTMLElement | null> = ref(null);

    // Data
    const error = ref(false);
    const pathView = ref(false);
    const homeView = ref(false);
    const congratsView = ref(false);

    // Methods
    const scrollToTop = ():void => {
        if (mainViewContainer.value) mainViewContainer.value.scrollTop = 0;
    };

    const scrollToPackages = (): void => {
        if (packagesContainer.value && mainViewContainer.value) {
            const anchorOffsetTop = packagesContainer.value.getBoundingClientRect().top;
            const containerOffsetTop = mainViewContainer.value.getBoundingClientRect().top;

            // Calculate the scroll position relative to the scroll container
            const scrollPosition = mainViewContainer.value.scrollTop + anchorOffsetTop - containerOffsetTop;

            // Smooth scrolling
            mainViewContainer.value.scrollTo({
                top: scrollPosition,
                behavior: 'smooth',
            });
        }
    };

    // eslint-disable-next-line max-statements
    const fetchPackages = async(): Promise<void> => {
        /*
         * The component will already render even though the FAB is not clicked yet,
         * therefore we need to check if it's open before loading actual data.
         */
        if (!props.initialized) return;

        // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
        venueData.value = null;
        homeView.value = true;
        pathView.value = false;
        congratsView.value = false;

        try {
            const response = await axios.get(`${import.meta.env.VITE_API_BASE_URL}/widget/api/venue/${props.venue}`, {
                headers: {
                    'Content-Type': 'application/json',
                    'Accept-Language': props.locale,
                },
            });
            // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment, @typescript-eslint/no-unsafe-member-access
            venueData.value = response.data?.venue || null;
        } catch (err) {
            error.value = true;
            // eslint-disable-next-line no-console
            console.error('Backend call failed', err);
        }
    };

    const openPath = ():void => {
        pathView.value = true;
        scrollToTop();
    };

    const resetVenueHost = (): void => {
        eventBus?.emit(VENUE_HOST_RESET);
        eventBus?.emit(VENUE_HOST_EVENT, { step: 0 });
    };

    const resetToMain = ():void => {
        error.value = false;
        homeView.value = true;
        pathView.value = false;
        congratsView.value = false;
        resetVenueHost();
    };

    watch(() => props.locale, fetchPackages);

    watch(() => props.initialized, fetchPackages);

    watch(homeView, (nVal: boolean) => {
        if (nVal) {
            resetVenueHost();
        }
    });
</script>

<template>
    <div class="main-view">
        <div class="main-view__container">
            <div class="main-view__container__main">
                <CustomTitle />
                <div
                    id="main-content"
                    ref="mainViewContainer"
                    class="main-view__content"
                >
                    <div class="main-host-target">
                        <div
                            id="main-host-target"
                            class="main-host-target__inner"
                        />
                    </div>
                    <VenueHost
                        v-if="isOpen"
                        :venue-manager="venueData?.venueManager"
                    />
                    <div
                        v-if="pathView"
                        class="main-view__path-section"
                    >
                        <PathView
                            :max-guest-amount="maxGuestAmount"
                            :min-guest-amount="minGuestAmount"
                            :guest-amount-step="guestAmountStep"
                            :max-budget-amount="maxBudgetAmount"
                            :min-budget-amount="minBudgetAmount"
                            :budget-step="budgetStep"
                            :locale="locale"
                            :error="error"
                            :venue="venue"
                            :brand-color="brandColor"
                            @back-to-main="resetToMain"
                        />
                    </div>
                    <div
                        v-else
                        class="main-view__overview-section"
                    >
                        <WelcomeScreen
                            v-if="homeView && isOpen"
                            :venue-name="venueData?.name ?? null"
                            :venue-event-types="venueEventTypes"
                            :brand-color="brandColor"
                            @next="openPath"
                            @go-to-packages="scrollToPackages"
                        />
                        <div ref="packagesContainer">
                            <PackageSelection
                                :locale="locale"
                                :venue="venue"
                                :error="error"
                                @scroll-to-top="scrollToTop"
                                @toggle-home="homeView = $event"
                                @process-request="congratsView = true"
                                @back-to-main="resetToMain"
                            />
                        </div>
                    </div>
                    <div
                        v-if="!pathView && !congratsView"
                        class="main-view__footer"
                    >
                        {{ t('widget.global.made-constell') }}
                    </div>
                </div>
                <div class="main-view__footer-shadow" />
            </div>
            <SideBar />
        </div>
    </div>
</template>

<style lang="scss" scoped>
.main-view {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    border-radius: var(--border-radius-xs);
    block-size: 100%;
    transition: background-color 300ms linear;

    .main-host-target {
        position: absolute;
        z-index: var(--zindex-stack-thrice);
        overflow: hidden;
        block-size: 100%;
        inline-size: 100%;
        inset-block: 0 0;
        inset-inline: 0 0;
        pointer-events: none;

        &__inner {
            position: relative;
            overflow: hidden;
            block-size: 100%;
            inline-size: 100%;
        }
    }

    &__container {
        display: flex;
        overflow: hidden;
        justify-content: space-between;
        block-size: 100%;

        &__main {
            flex: 2;
            background-color: var(--background-secondary);
            block-size: 100%;
            inline-size: 100%;
            transform: scale(1);
        }
    }

    &__content {
        position: relative;
        z-index: var(--zindex-stack-twice);
        block-size: calc(100% - var(--widget-header-height));
        -ms-overflow-style: none; /* IE & Edge */
        overflow-y: auto;
        scrollbar-width: none; /* Firefox */

        &::-webkit-scrollbar {
            display: none; /* Chrome, Safari */
        }
    }

    &__overview-section,
    &__path-section {
        display: flex;
        flex-direction: column;
    }

    &__path-section {
        position: relative;
        overflow: hidden;
        block-size: 100%;
        min-block-size: 100%;
    }

    &__footer-shadow {
        position: fixed;
        z-index: var(--zindex-stack-twice);
        background: linear-gradient(0deg, rgb(0 0 0 / 0.30) 0%, rgb(239 239 239 / 0) 100%);
        block-size: var(--size-10);
        inline-size: 100%;
        inset-block-end: 0;
        inset-inline-start: 0;
        pointer-events: none;
    }

    &__footer {
        padding: var(--spacing-02);
        margin: var(--spacing-02);
        color: var(--text-tertiary);
        font-size: var(--widget-font-regular);
        letter-spacing: 0.5px;
        text-align: center;
        text-transform: uppercase;

        @include min-width(md) {
            padding: var(--spacing-04);
            margin: var(--spacing-04);
        }
    }

    @include min-width(md) {
        border-radius: var(--border-radius-md);
    }
}

</style>
