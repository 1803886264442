<script setup lang="ts">
    import BackButton from '@/components/BackButton.ce.vue';
    import FilterBar from '@/components/FilterBar.ce.vue';
    import PackagesList from '@/components/PackagesList.ce.vue';
    import { type PackageTyping } from '@/typings/widget';
    import FormView from '@/views/FormView.ce.vue';
    import PackageView from '@/views/PackageView.ce.vue';
    import SubmitConfirmationView from '@/views/SubmitConfirmationView.ce.vue';
    import { type Ref } from 'vue';

    const props = defineProps<{
        venue: string
        locale: string
        hideFilter?: boolean
        hideTitle?: boolean
        inPath?: boolean
    }>();

    // eslint-disable-next-line func-call-spacing
    const emit = defineEmits<{
        (event: 'scrollToTop' | 'backToMain' | 'processRequest' | 'backToPath'): void
        (event: 'toggleHome', value: boolean): void
        (event: 'filteredPackages', value: number): void
    }>();

    const { t } = useI18n();

    // Stores
    const { venue: venueData } = storeToRefs(useWidgetVenueStore());

    // Refs
    const packageListView = ref(true);
    const formView = ref(false);
    const confirmationView = ref(false);
    const packageItem: Ref<PackageTyping> = ref({} as PackageTyping);
    const filteredPackages: Ref<PackageTyping[]> = ref([]);
    const guestAmount = ref(0);

    // Methods
    const filterPackages = (selectedItem: number) :void => {
        if (!selectedItem || selectedItem === 0) {
            // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment, @typescript-eslint/no-unsafe-member-access
            filteredPackages.value = venueData.value?.packages ?? [];
            return;
        }

        // eslint-disable-next-line @typescript-eslint/no-unsafe-call, @typescript-eslint/no-unsafe-assignment, @typescript-eslint/no-unsafe-member-access
        filteredPackages.value = venueData.value?.packages.filter(item => item.eventType.id === selectedItem) ?? [];
    };

    const setGuestAmount = (amount: string | null):void => {
        guestAmount.value = Number(amount);
    };

    const openPackageItem = (selectedItem: PackageTyping):void => {
        // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
        packageItem.value = selectedItem;
        packageListView.value = false;
        emit('scrollToTop');
    };

    const handleRequest = ():void => {
        confirmationView.value = true;
        emit('processRequest');
    };

    // eslint-disable-next-line max-statements, complexity
    const goBack = ():void => {
        // Go back from form view to package view
        if (!packageListView.value && formView.value && !confirmationView.value) {
            formView.value = false;
            return;
        }

        // Go back from package view to package list
        if (packageListView.value && !formView.value && !confirmationView.value) {
            packageListView.value = false;

            // Go back to path
            if (props.inPath) emit('backToPath');
            return;
        }

        // Reset all views
        if (confirmationView.value){
            confirmationView.value = false;
            formView.value = false;
            packageListView.value = true;
            emit('backToMain');
            return;
        }

        formView.value = false;
        packageListView.value = true;
    };

    watch(() => venueData.value, nVal => {
        if (nVal) filteredPackages.value = nVal?.packages ?? [];
    });

    watch(() => packageListView.value, (nVal: boolean) => {
        emit('toggleHome', nVal);
    });

    watch(() => filteredPackages.value, nVal => {
        if (nVal) emit('filteredPackages', nVal.length);
    });
</script>

<template>
    <div class="package-selection">
        <div
            v-if="packageListView"
            class="package-selection__list"
        >
            <div
                v-if="inPath"
                class="package-selection__back-btn"
            >
                <BackButton :on-click="goBack" />
            </div>
            <h2 v-if="!hideTitle">
                {{ t('widget.titles.ourPackages') }}
            </h2>
            <FilterBar
                v-show="!hideFilter"
                @select-types="filterPackages"
                @guest-amount-changes="setGuestAmount"
            />
            <PackagesList
                :packages="filteredPackages"
                @package-selected="openPackageItem"
            />
        </div>
        <div
            v-else
            class="package-selection__form"
        >
            <div
                v-if="!confirmationView"
                class="package-selection__back-btn"
            >
                <BackButton :on-click="goBack" />
            </div>
            <div v-if="!formView">
                <PackageView
                    :package-item="packageItem"
                    @show-book-view="formView = true"
                />
            </div>
            <div v-if="formView && !confirmationView">
                <FormView
                    :venue="venue"
                    :guest-amount-filter="guestAmount"
                    :package-id="packageItem.id"
                    :locale="locale"
                    @process-request="handleRequest"
                    @scroll-to-top="emit('scrollToTop')"
                />
            </div>
            <div v-if="formView && confirmationView">
                <SubmitConfirmationView
                    @back-to-main="goBack"
                />
            </div>
        </div>
    </div>
</template>

<style lang="scss" scoped>
.package-selection {
    &__list {
        h2 {
            font-size: var(--widget-font-h1);
            margin-block-start: var(--spacing-04);
            text-align: center;
        }
    }

    &__back-btn {
        line-height: 1;
        margin-block: var(--spacing-04);
        margin-inline: 0;
        padding-block: 0;
        padding-inline: var(--widget-inner-gutter);
    }
}
</style>
