<!-- eslint-disable @typescript-eslint/no-magic-numbers -->
<script lang="ts" setup>
    import formKitConfig from '@/formkit';
    import { defaultConfig, FormKitProvider, FormKitRoot } from '@formkit/vue';
    import TextInput from '@shared/components/form/text-input.vue';
    import { useI18n } from 'vue-i18n';

    const { t } = useI18n();

    const props = defineProps<{
        modelValue: string;
        step: number
    }>();

    // eslint-disable-next-line func-call-spacing
    const emit = defineEmits<{
        (event: 'update:modelValue', value: string): void
        (event: 'next', value: number): void
    }>();

    const ENTRY_DELAY = 100;
    const EXIT_DELAY = 1000;
    const STEP_MIDDLE = 0.5;
    const STEP_NEXT = 1;

    const nameModel = ref(props.modelValue ?? '');
    const showElement = ref(false);
    const exitElement = ref(false);

    const handleExitStep = ():void => {
        exitElement.value = true;
        emit('next', props.step + STEP_MIDDLE);
        setTimeout(() => {
            emit('next', props.step + STEP_NEXT);
        }, EXIT_DELAY);
    };

    const resetAnimationState = ():void => {
        showElement.value = false;
        exitElement.value = false;

        // Trigger the "showElement" transition again after a brief delay
        setTimeout(() => {
            showElement.value = true;
        }, ENTRY_DELAY);
    };

    onMounted(() => {
        resetAnimationState();
    });

    watch(() => nameModel.value, (nVal: string) => {
        emit('update:modelValue', nVal);
    });
</script>

<template>
    <div
        :class="{'fade-in': showElement, 'slide-up': exitElement}"
        class="step-name"
    >
        <div class="step-name__inner">
            <div class="step-name__title">
                {{ t('widget.titles.ask-name') }}
            </div>
            <div class="step-name__fields">
                <FormKitProvider :config="defaultConfig(formKitConfig)">
                    <FormKitRoot>
                        <TextInput
                            v-model="nameModel"
                            :placeholder="t('widget.form.name')"
                            name="name"
                        />
                    </FormKitRoot>
                </FormKitProvider>
            </div>
            <div class="step-name__actions">
                <button
                    :class="{'btn--secondary': !nameModel, 'btn--primary': nameModel}"
                    :disabled="!nameModel"
                    type="button"
                    class="btn btn--thin step-name__action"
                    @click="handleExitStep"
                >
                    {{ t('widget.buttons.continue') }}
                </button>
            </div>
        </div>
    </div>
</template>

<style scoped lang="scss">
    .step-name {
        display: flex;
        flex-direction: column;
        align-items: stretch;
        block-size: 100%;
        inline-size: 100%;
        opacity: 0;
        transform: translateY(0);
        transition: all 0.75s ease-in-out;

        @include min-width(md) {
            flex-direction: row;
            align-items: center;
            justify-content: center;
        }

        &__inner {
            display: flex;
            flex-direction: column;
            flex-grow: 1;
            align-items: stretch;
            padding-block: var(--spacing-08) var( --spacing-06);
            padding-inline: var(--spacing-05);

            @include min-width(md) {
                inline-size: 100%;
                max-inline-size: 487px;
                padding-block: 0;
            }
        }

        &__title {
            font-family: var(--font-secondary);
            font-size: var(--widget-font-h2);
            font-weight: var( --font-weight-semi-bold);
            letter-spacing: var( --letter-spacing-sm);
            line-height: var(--line-height-150);
            margin-block-end: var(--spacing-02);
            text-align: center;

            @include min-width(md) {
                text-align: start;
            }
        }

        &__fields {
            display: flex;
            flex-direction: column;
            gap: var( --spacing-03);

        }

        &__actions {
            display: flex;
            justify-content: center;
            margin-block-start: auto;

            @include min-width(md) {
                justify-content: flex-end;
                margin-block-start: var(--spacing-03);
            }
        }

        &__action {
            min-inline-size: 180px;
        }

        &.fade-in {
            opacity: 1;
        }

        &.slide-up {
            transform: translateY(-100%);
        }
    }
</style>
