<script setup lang="ts">
    import '@/assets/images/illustration-glasses.svg';
    import { useI18n } from 'vue-i18n';

    defineEmits<{(event: 'backToMain')}>();

    const { t } = useI18n();

    const GLASSES_IMG = `${import.meta.env.VITE_CDN_URL}/assets/illustration-glasses.svg`;

</script>

<template>
    <div class="complete-container">
        <img
            class="illustration"
            :src="GLASSES_IMG"
        >
        <h1>{{ t('widget.titles.amazing') + '!' }}</h1>
        <p>{{ t('widget.messages.request-success') }}</p>
        <a
            class="link-btn"
            @click="$emit('backToMain')"
        >
            {{ t('widget.buttons.back-main') }}
        </a>
    </div>
</template>

<style scoped lang="scss">
.complete-container {
    position: absolute;
    z-index: var(--zindex-stack-twice);
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    block-size: 100%;
    inline-size: 300px;
    inset-inline: 0;
    margin-inline: auto;

    .illustration {
        block-size: 139px;
        inline-size: 100px;
    }

    p {
        margin-block-end: var(--spacing-07);
        text-align: center;
    }

    a {
        font-weight: var(--font-weight-medium);
        text-decoration: underline;
    }
}
</style>
