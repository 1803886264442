<!-- eslint-disable @typescript-eslint/no-magic-numbers -->
<script lang="ts" setup>
    import { useI18n } from 'vue-i18n';

    interface Item {
        id: number
        name: string
        icon?: string
    }

    const props = defineProps<{
        modelValue?: number | number[]
        items: Item[]
        step: number
        title?: string
        subtitle?: string
        multiple?: boolean
        withButton?: boolean
        readOnly?: boolean
        autoExit?: boolean
    }>();

    // eslint-disable-next-line func-call-spacing
    const emit = defineEmits<{
        (event: 'update:modelValue', value: number | number[]): void
        (event: 'next', value: number)
        (event: 'started' | 'selected')
    }>();

    const { t } = useI18n();

    const ENTRY_DELAY = 100;
    const EXIT_DELAY = 1000;
    const STEP_MIDDLE = 0.5;
    const STEP_NEXT = 1;
    const WAITING_DELAY = 5000;

    const selectedModel = ref(props.modelValue ?? -1);
    const showElement = ref(false);
    const showChildElement = ref(false);
    const exitElement = ref(false);

    const isDisabled = computed(() => !selectedModel.value || !(selectedModel.value as number[]).length);

    const handleExitStep = ():void => {
        exitElement.value = true;
        setTimeout(() => {
            emit('next', props.step + STEP_MIDDLE);
            setTimeout(() => {
                emit('next', props.step + STEP_NEXT);
            }, EXIT_DELAY);
        }, EXIT_DELAY);
    };

    const handleSelection = (id: number): void => {
        emit('selected');

        if (props.multiple) {
            const selectionIndex = (selectedModel.value as number[]).findIndex(item => item === id);
            if (selectionIndex > -1) (selectedModel.value as number[]).splice(selectionIndex, 1);
            else (selectedModel.value as number[]).push(Number(id));
        } else {
            selectedModel.value = Number(id);
        }
        if (!props.withButton) handleExitStep();
    };

    const resetAnimationState = ():void => {
        showElement.value = false;
        showChildElement.value = false;
        exitElement.value = false;

        // Trigger the "showElement" transition again after a brief delay
        setTimeout(() => {
            showElement.value = true;
            setTimeout(() => {
                showChildElement.value = true;
            }, EXIT_DELAY * 3);
        }, ENTRY_DELAY);
    };

    onMounted(() => {
        resetAnimationState();
        emit('started');
        if (props.autoExit) {
            setTimeout(() => {
                handleExitStep();
            }, WAITING_DELAY);
        }
    });

    watch(() => selectedModel.value, (nVal: number | number[]) => {
        emit('update:modelValue', nVal);
    });
</script>

<template>
    <div
        :class="{'slide-bottom-up': showElement && !exitElement, 'slide-up': exitElement}"
        class="options-card"
    >
        <div class="options-card__content">
            <h2
                v-if="title"
                class="options-card__title"
            >
                {{ title }}
            </h2>
            <p
                v-if="subtitle"
                class="options-card__subtitle"
            >
                {{ subtitle }}
            </p>
            <div
                :class="{'options-card__container--visible': showChildElement}"
                class="options-card__container"
            >
                <div class="options-card__list">
                    <button
                        v-for="(item, index) in items"
                        :key="index"
                        :class="{
                            'options-card__item--selected': multiple ? (selectedModel as number[]).includes(item.id) : selectedModel === item.id,
                            'options-card__item--read-only': readOnly
                        }"
                        class="options-card__item"
                        type="button"
                        @click="handleSelection(item?.id)"
                    >
                        <span>
                            <template v-if="item.icon">
                                {{ `${item.icon} ` }}
                            </template>
                            {{ t(item.name) }}
                        </span>
                    </button>
                </div>
                <button
                    v-if="withButton"
                    :class="{
                        'btn--secondary': isDisabled,
                        'btn--primary': !isDisabled,
                    }"
                    :disabled="isDisabled"
                    type="button"
                    class="options-card__action btn btn--thin"
                    @click="handleExitStep"
                >
                    {{ t('widget.buttons.next') }}
                </button>
            </div>
        </div>
    </div>
</template>

<style scoped lang="scss">
    .options-card {
        position: absolute;
        display: flex;
        flex-direction: column;
        align-items: stretch;
        justify-content: center;
        block-size: 100%;
        inline-size: 100%;
        inset-block-start: 0;
        transform: translateY(100%);
        transition: all 0.75s ease-in-out;

        @include min-width(md) {
            flex-direction: row;
            align-items: center;
        }

        &__content,
        &__container {
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: flex-start;
        }

        &__content {
            max-block-size: 100%;
            padding-block: var( --spacing-06);
        }

        &__title {
            color: var(--text-primary);
            font-family: var(--font-secondary);
            font-size: var(--widget-font-h1);
            font-weight: var(--font-weight-semi-bold);
            letter-spacing: var(--letter-spacing-sm);
            line-height: var(--line-height-150);
            margin-block-end: var(--spacing-02);
            padding-inline: var(--spacing-05);
            text-align: center;
        }

        &__subtitle {
            color: var(--text-primary);
            font-family: var(--font-primary);
            font-size: var(--widget-font-h4);
            font-weight: var(--font-weight-light);
            line-height: var(--line-height-150);
            padding-inline: var(--spacing-05);
            text-align: center;
        }

        &__container {
            block-size: 100%;
            inline-size: 100%;
            margin-block-start: var( --spacing-04);
            max-block-size: 0;
            opacity: 0;
            padding-inline: var(--spacing-05);
            transform: translateY(100%);
            transition: all 1s ease-in-out;
            visibility: hidden;

            &--visible {
                overflow: hidden auto;
                max-block-size: 80vh;
                opacity: 1;
                transform: translateY(0);
                visibility: visible;

                @include min-width(lg) {
                    overflow: hidden;
                    max-block-size: 400px;
                }
            }
        }

        &__list {
            display: flex;
            flex-direction: column;
            gap: var(--spacing-02);
            inline-size: 100%;

            @include min-width(md) {
                flex-flow: row wrap;
                align-items: center;
                justify-content: center;
                padding-inline: var(--spacing-03);
            }
        }

        &__item {
            display: flex;
            align-items: center;
            justify-content: flex-start;
            padding: calc(var(--spacing-04) - 4px);
            border: 1px solid var(--color-white);
            border-radius: 10px;
            background: rgb(255 255 255 / 0.4);
            transition: all 0.4s ease-in-out;

            &--selected {
                padding: calc(var(--spacing-05) - 2px);
                border-color: var(--color-black-space-10);
                background-color: var(--color-pure-white);

                span {
                    font-weight: var(--font-weight-semi-bold);
                }
            }

            &--read-only {
                cursor: default;
                pointer-events: none;
            }

            span {
                display: flex;
                font-size: var(--widget-font-regular);
                font-weight: var(--font-weight-light);
                gap: var(--spacing-04);
                inline-size: 100%;
            }

            &:hover {
                padding: calc(var(--spacing-05) - 2px);
                background-color: var(--color-pure-white);
            }
        }

        &__action {
            margin-block-start: var(--spacing-04);
            min-inline-size: 180px;
        }

        &.slide-bottom-up{
            transform: translateY(0);
        }

        &.slide-up{
            transform: translateY(-100%);
        }
    }
</style>
