import { type FormKitNode, getNode, type FormKitAddress } from '@formkit/core';
import { ConstantsDateTime, DateTime } from '@constell-nl/starlight';

// Checks is FormKit form field is a correct price format
export const validateDecimalFormat = (currentNode: FormKitNode): boolean => {
    // Regex that represents a price
    const regex = /^[0-9]+(?:\.[0-9]{1,2})?$/gu;
    const matchesRegex: boolean = regex.test(String(currentNode.value));
    return matchesRegex;
};

// Checks if FormKit date-time field is higher than or equal to given date-time string
export const validateSameOrAfterDateTime = (currentNode: FormKitNode, dateTimeToCompare: string): boolean => {
    if (currentNode.value && dateTimeToCompare) {
        return DateTime.isDateSameOrAfter(
            String(currentNode.value),
            dateTimeToCompare,
            'minute',
        );
    }
    return false;
};

// Checks if FormKit date-time field is lower than or equal to given date-time string
export const validateSameOrBeforeDateTime = (currentNode: FormKitNode, dateTimeToCompare: string): boolean => {
    if (currentNode.value && dateTimeToCompare) {
        return DateTime.isDateSameOrBefore(
            String(currentNode.value),
            dateTimeToCompare,
            'minute',
        );
    }
    return false;
};

// Checks if FormKit date-time field is higher than other date-time field
export const validateAfterDateTimeField = (currentNode: FormKitNode, fieldToCompareId: string): boolean => {
    if (currentNode.value && fieldToCompareId) {
        const nodeToCompare = getNode(fieldToCompareId);
        if (nodeToCompare?.value) {
            // If it's not the same or before, it must be after
            return !DateTime.isDateSameOrBefore(
                String(currentNode.value),
                String(nodeToCompare.value),
                'minute',
            );
        }
    }
    return false;
};

// Checks if FormKit date field is higher than other date field
export const validateAfterDateField = (currentNode: FormKitNode, fieldToCompareId: string): boolean => {
    if (currentNode.value && fieldToCompareId) {
        const nodeToCompare = getNode(fieldToCompareId);
        if (nodeToCompare?.value) {
            // Interpret the date in the correct format
            const currentDate = DateTime.dayjsFormatter(String(currentNode.value), ConstantsDateTime.dateFormatFormKit);
            const compareDate = DateTime.dayjsFormatter(String(nodeToCompare.value), ConstantsDateTime.dateFormatFormKit);

            // If it's not the same or before, it must be after
            return !DateTime.isDateSameOrBefore(
                currentDate,
                compareDate,
                'date',
            );
        }
    }
    return false;
};

// Checks if FormKit date-time field has a minimum number of days higher than another date field
export const validateDaysBetween = (
    currentNode: FormKitNode,
    fieldToCompareId: string,
    minimumNumberOfDays: number,
): boolean => {
    if (currentNode.value && fieldToCompareId) {
        const nodeToCompare = getNode(fieldToCompareId);
        if (nodeToCompare?.value) {
            // Interpret the date in the correct format
            const currentDate = DateTime.dayjsFormatter(String(currentNode.value), ConstantsDateTime.dateFormatFormKit);
            const compareDate = DateTime.dayjsFormatter(String(nodeToCompare.value), ConstantsDateTime.dateFormatFormKit);

            // Check if the difference between the two fields is higher than or equal to the minimum
            return currentDate.diff(compareDate, 'day') >= minimumNumberOfDays;
        }
    }
    return false;
};

// Checks if FormKit date-time field falls within two other date-time fields
export const validateWithinDates = (
    currentNode: FormKitNode,
    startTimeField: string,
    endTimeField: string,
): boolean => {
    if (currentNode.value && startTimeField && endTimeField) {
        const startTimeNode = getNode(startTimeField);
        const endTimeNode = getNode(endTimeField);
        if (startTimeNode?.value && endTimeNode?.value && startTimeNode?.value) {
            // Check if the date is between the start and the end date
            const isSameOrAfterStartDate = DateTime.isDateSameOrAfter(
                String(currentNode.value),
                String(startTimeNode.value),
                'minute',
            );
            const isSameOrBeforeEndDate = DateTime.isDateSameOrBefore(
                String(currentNode.value),
                String(endTimeNode.value),
                'minute',
            );
            return isSameOrAfterStartDate && isSameOrBeforeEndDate;
        }
    }
    return false;
};

export const useGreaterThanValidation = (node: FormKitNode, otherName: FormKitAddress): boolean => {
    const otherNode = node.at(otherName);
    if (otherNode) return Number(node.value) > Number(otherNode.value);
    return true;
};
